import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';
import useConfig from 'hooks/useConfig';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Container } from '@mui/material';
import { IconChevronRight } from '@tabler/icons';

import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import { openDrawer } from 'store/slices/menu';
import { drawerWidth } from 'store/constant';
import { useDispatch, useSelector } from 'store';
import Sidebar from './Sidebar';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter
      }),
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`
      },
      [theme.breakpoints.down('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '16px'
      },
      [theme.breakpoints.down('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '16px'
      }
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    })
  })
);

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { container } = useConfig();

  React.useEffect(() => {
    dispatch(openDrawer(!matchDownMd));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  const header = useMemo(
    () => (
      <Toolbar>
        <Header />
      </Toolbar>
    ),
    []
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default
        }}
      >
        {header}
      </AppBar>
      <Sidebar />
      <Main theme={theme} open={drawerOpen}>
        {/* breadcrumb */}
        {container && (
          <Container maxWidth="md">
            <Breadcrumbs separator={IconChevronRight} icon title rightAlign />
            <Outlet />
          </Container>
        )}
        {!container && (
          <>
            <Breadcrumbs separator={IconChevronRight} icon title rightAlign />
            <Outlet />
          </>
        )}
      </Main>
    </Box>
  );
};

export default MainLayout;
