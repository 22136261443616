import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Card, Grid, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconCloudSnow } from '@tabler/icons';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import FilterDramaTwoToneIcon from '@mui/icons-material/FilterDramaTwoTone';

const CloudStatus = ({ tempdata }) => {
  const theme = useTheme();
  let widgetIcon = <></>;
  if (tempdata.widget === 'Rainy') {
    widgetIcon = <ThunderstormIcon fontSize="large" />;
  } else if (tempdata.widget === 'Snow') {
    widgetIcon = <IconCloudSnow fontSize="large" />;
  } else {
    widgetIcon = <FilterDramaTwoToneIcon fontSize="large" />;
  }
  return (
    <>
      <Grid container justifyContent="flex-end" spacing={gridSpacing}>
        <Grid item xs={12} sx={{ pt: '10px !important' }}>
          <Card sx={{ bgcolor: theme.palette.orange.light }}>
            <Grid item>
              <Grid spacing={0.2} sx={{ p: 1 }} item xs={12}>
                <Grid item sm={12}>
                  <Typography variant="h2" color="#000000" align="center">
                    {tempdata.time}
                  </Typography>
                </Grid>
                <Grid item sm={12}>
                  <Typography color="#000000" variant="h6" align="center" sx={{ fontSize: 13 }}>
                    {tempdata.dayDate}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                  sm={12}
                >
                  <Grid item>
                    <Typography
                      align="center"
                      sx={{ fontSize: 12 }}
                      variant="subtitle1"
                      color="#000000"
                    >
                      {tempdata.widget}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: '#EC5C13',
                        '&> svg': {
                          width: 30,
                          height: 30,
                          opacity: '0.7',
                          transition: 'all .3s ease-in-out'
                        }
                      }}
                      noWrap
                    >
                      {widgetIcon}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

CloudStatus.propTypes = {
  tempdata: PropTypes.object
};
export default CloudStatus;
