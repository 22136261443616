import { memo } from 'react';

// project imports
import NavGroup from './NavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => <NavGroup />;

export default memo(MenuList);
