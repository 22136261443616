import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import { IconMap2 } from '@tabler/icons';

export default function MapLegendButton({ onClick }) {
  const theme = useTheme();

  const color = theme.palette.primary.main;
  const background = theme.palette.primary.light;

  return (
    <Box
      sx={{
        mr: 2,
        [theme.breakpoints.down('md')]: {
          ml: 1
        }
      }}
    >
      <Button
        // variant="outlined"
        startIcon={<IconMap2 />}
        onClick={onClick}
        sx={{
          background,
          color,
          transition: 'all .2s ease-in-out',
          '&[aria-controls="menu-list-grow"],&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.light
          }
        }}
      >
        Map Legend
      </Button>
    </Box>
  );
}

MapLegendButton.propTypes = {
  onClick: PropTypes.func
};
