import { methaneEqScaleFn, radiantHeatScaleFn } from 'utils/filterUtils';

export const detectFreqFilterConfig = {
  label: 'Detection Frequency',
  valueText: (value) => `${value}%`,
  marks: [
    { value: 0, label: '0%' },
    { value: 25, label: '25%' },
    { value: 50, label: '50%' },
    { value: 75, label: '75%' },
    { value: 100, label: '100%' }
  ]
};

export const methaneEqFilterConfig = {
  label: 'Flared Gas',
  min: 0,
  max: 11,
  step: 0.01,
  marks: [
    { value: 0, label: '0' },
    { value: 2.2, label: `${parseFloat(methaneEqScaleFn(2.2) * 1000).toFixed(2)}` },
    { value: 4.4, label: `${parseFloat(methaneEqScaleFn(4.4) * 1000).toFixed(2)}` },
    { value: 6.6, label: `${parseFloat(methaneEqScaleFn(6.6) * 1000).toFixed(2)}` },
    { value: 8.8, label: `${parseFloat(methaneEqScaleFn(8.8) * 1000).toFixed(2)}` },
    { value: 11, label: `${parseFloat(methaneEqScaleFn(11) * 1000).toFixed(2)}` }
  ],
  valueLabelFormat: (x) => (x * 1000).toFixed(2)
};

export const radiantHeatFilterConfig = {
  label: 'Radiant Heat',
  min: 0,
  max: 17,
  step: 0.01,
  marks: [
    { value: 0, label: '0 MW' },
    { value: 3.4, label: `${parseFloat(radiantHeatScaleFn(3.4)).toFixed(3)}` },
    { value: 6.8, label: `${parseFloat(radiantHeatScaleFn(6.8)).toFixed(3)}` },
    { value: 10.2, label: `${parseFloat(radiantHeatScaleFn(10.2)).toFixed(2)}` },
    { value: 13.6, label: `${parseFloat(radiantHeatScaleFn(13.6)).toFixed(2)}` },
    { value: 17, label: `${parseFloat(radiantHeatScaleFn(17)).toFixed(2)}` }
  ],
  valueLabelFormat: (x) => `${parseFloat(radiantHeatScaleFn(x)).toFixed(4)}`
};
