import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

import getCountryNameFromIso from 'utils/isoToCountryConverter';
import { viirsLayer } from 'config/layers';

export default function InfoTpl({ properties, countries }) {
  const {
    lat,
    lon,
    iso,
    type,
    area,
    id,
    category,
    bcm,
    t_mean: tMean,
    rh,
    pct,
    layerId,
    COUNTRY
  } = properties;

  return (
    <Stack>
      {type && <b>{type.toUpperCase()}</b>}
      {id && (
        <span>
          {layerId === viirsLayer.id ? 'Flare' : 'Emitter'} <b>#{id}</b>
        </span>
      )}
      {category && (
        <span>
          Category <b>{category}</b>
        </span>
      )}
      {(iso || COUNTRY) && (
        <span>
          Located in <b>{getCountryNameFromIso(countries, iso, COUNTRY)}</b>
        </span>
      )}
      {bcm !== undefined && parseFloat(bcm) !== 0 && (
        <span>
          Flared Gas
          <b>{parseFloat(bcm * 1000).toFixed(3)} mcm/yr</b>
        </span>
      )}
      {tMean !== undefined && parseInt(tMean, 10) !== 0 && (
        <span>
          Avg Temp <b>{parseInt(tMean, 10).toLocaleString()} K</b>
        </span>
      )}
      {rh !== undefined && parseFloat(rh) !== 0 && (
        <span>
          Radiant Heat <b>{parseFloat(rh).toFixed(2)} MW</b>
        </span>
      )}
      {area !== undefined && parseFloat(area) !== 0 && (
        <span>
          Area{' '}
          <b>
            {parseFloat(parseFloat(area).toFixed(2)).toLocaleString()} m<sup>2</sup>
          </b>
        </span>
      )}
      {pct !== undefined && parseFloat(pct) !== 0 && (
        <span>
          Detection Frequency <b>{parseFloat(pct * 100).toFixed(2)}%</b>
        </span>
      )}
      {lat !== undefined && parseFloat(lat) !== 0 && (
        <span>
          <b>
            {parseFloat(lat).toFixed(6)}, {parseFloat(lon).toFixed(6)}
          </b>
        </span>
      )}
      <span>
        <i>Click to see details...</i>
      </span>
    </Stack>
  );
}

InfoTpl.propTypes = {
  properties: PropTypes.object,
  countries: PropTypes.array
};
