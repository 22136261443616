import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';

import CircularLoading from 'ui-component/cards/CircularLoading';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useSelector } from 'store';

export default function LoginButton({ onClick, disable }) {
  const userState = useSelector((state) => state.user);
  const { loading } = userState;

  const handleLoginOnEnter = (event) => {
    if (event.keyCode === 13) {
      onClick();
    }
  };

  return (
    <Box sx={{ mt: 5 }}>
      {loading ? (
        <CircularLoading />
      ) : (
        <AnimateButton>
          <Button
            color="secondary"
            fullWidth
            size="large"
            variant="contained"
            onClick={onClick}
            disabled={disable}
            onKeyPress={handleLoginOnEnter}
          >
            LOGIN
          </Button>
        </AnimateButton>
      )}
    </Box>
  );
}

LoginButton.propTypes = {
  onClick: PropTypes.func
};
