import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import backgroundsReducer from './slices/backgrounds';
import layerReducer from './slices/layers';
import userReducer from './slices/user';
import dashboardReducer from './slices/dashboard';
import filtersReducer from './slices/filters';
import mapReducer from './slices/map';
import searchFilterReducer from './slices/searchFilter';
import aggregatedProfileReducer from './slices/aggregatedProfiles';
import staticTextsReducer from './slices/staticTexts';

const reducer = combineReducers({
  snackbar: snackbarReducer,
  menu: menuReducer,
  backgrounds: backgroundsReducer,
  layers: layerReducer,
  user: persistReducer(
    {
      key: 'user',
      storage,
      keyPrefix: 'IREm-'
    },
    userReducer
  ),
  dashboard: dashboardReducer,
  filters: filtersReducer,
  map: mapReducer,
  searchFilter: searchFilterReducer,
  aggregatedProfile: aggregatedProfileReducer,
  staticTexts: staticTextsReducer
});

export default reducer;
