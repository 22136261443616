import { AuthRoles } from 'store/slices/user';
import { generateTypeStyle, temperatureStyle, energyStyle } from './styles';

const tileSource = `${process.env.REACT_APP_API_URL}/layers/catalog_multiyear_vnf/{z}/{x}/{y}.mvt`;

export const layerTypes = [
  'cement',
  'coal mine',
  'downstream',
  'greenhouse',
  'industrial',
  'landfill',
  'metallurgy',
  'sawmill',
  'unique',
  'unknown',
  'upstream',
  'volcano',
  '-'
];

const multiYearConfig = {
  id: 'multiyear',
  name: 'Multiyear 2012-2021 Global IR Emitter Catalog',
  description: 'Toggle to enable/disable layer',
  enabled: true, // optional, will default to false if not set here
  requiredAuthLevel: AuthRoles.Public,
  tooltipText: 'Public dataset layer',
  layerType: 'symbol',
  resourceId: 'multiyear_vnf',
  adminOnly: false,
  profileUrl: (id) =>
    `https://eogdata.mines.edu/wwwdata/hidden/vnf_profiles/profiles/site_${id}_multiyear_vnf_dashboard.png`,
  csvUrl: (id) =>
    `https://eogdata.mines.edu/wwwdata/hidden/vnf_profiles/profiles/flare_${id}_multiyear_vnf_series.csv`,
  metadata: {
    name: 'Multiyear 2012-2021 Global IR Emitter Catalog',
    labels: {
      '-': 'Unknown',
      downstream: 'Downstream Flare',
      flare: 'Upstream Oil Flare',
      gas: 'Upstream Gas Flare',
      gpp: 'Gas Processing',
      opp: 'Oil Processing',
      petrochem: 'Petrochemical',
      sawmill: 'Wood Processing',
      upstream: 'Upstream Flare',
      whflare: 'Upstream Flare',
      '400,1000': '400 - 1000 K',
      '1000,1200': '1000 - 1200 K',
      '1200,1400': '1200 - 1400 K',
      '1400,1600': '1400 - 1600 K',
      '1600,2500': '1600 - 2500 K',
      2500: '2500 K'
    }
  },
  sourceLayer: 'vnf',
  tileSource,
  layerSource: {
    attribution:
      '© <a href="https://eogdata.mines.edu/products/vnf/#credit" target="_blank">Earth Observation Group</a>',
    type: 'vector',
    tiles: [tileSource],
    minzoom: 1,
    maxzoom: 20
  },
  styles: {
    typeStyle: generateTypeStyle(layerTypes),
    temperatureStyle,
    energyStyle
  },
  paint: (radius, color) => ({
    'icon-color': color
  }),
  layout: (icon, size) => ({
    'icon-image': icon,
    'icon-size': size,
    'icon-allow-overlap': true
  }),
  sublayers: [
    {
      id: 'viirs-contour',
      layerType: 'line',
      metadata: {
        name: 'Site Contours',
        labels: {
          '#000': 'Outline (Login Req.)'
        }
      },
      sourceLayer: 'contour',
      paint: () => ({
        'line-color': '#000',
        'line-width': 1
      })
    }
  ]
};

export default multiYearConfig;
