import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Box,
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
  Modal
} from '@mui/material';
import { logout } from 'store/slices/user';
import { openSnackbar } from 'store/slices/snackbar';
import { dispatch, useSelector } from 'store';
import styled from '@emotion/styled';
import { IconLogin, IconExternalLink, IconMessage, IconLogout } from '@tabler/icons';
import useConfig from 'hooks/useConfig';
import Feedback from '../FeedbackModal/Feedback';

export default function LoginMenuOption({ onClick }) {
  const { borderRadius } = useConfig();
  const userState = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const height = 680;
  const width = 600;

  const handleLogout = () => {
    dispatch(logout());
    dispatch(
      openSnackbar({
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        open: true,
        message: 'You are logged out',
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const ModalContent = styled(Box)`
    position: absolute;
    top: calc(50vh - ${height / 2}px);
    left: calc(50vw - ${width / 2}px);
    width: ${width}px;
    height: ${height}px;
    background: white;
    border-radius: 15px;
    outline: none;
  `;

  return (
    <>
      <Link
        href="https://payneinstitute.mines.edu/eog/viirs-nightfire-vnf/"
        target="_blank"
        rel="noreferrer"
        underline="none"
      >
        <ListItemButton sx={{ borderRadius: `${borderRadius}px` }}>
          <ListItemIcon>
            <IconExternalLink stroke={1.5} size="20px" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body2">Citation</Typography>} />
        </ListItemButton>
      </Link>
      <Link
        href="https://eogdata.mines.edu/products/vnf/#data_format"
        target="_blank"
        rel="noreferrer"
        underline="none"
      >
        <ListItemButton sx={{ borderRadius: `${borderRadius}px` }}>
          <ListItemIcon>
            <IconExternalLink stroke={1.5} size="20px" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body2">Documentation</Typography>} />
        </ListItemButton>
      </Link>
      <ListItemButton sx={{ borderRadius: `${borderRadius}px` }} onClick={handleOpen}>
        <ListItemIcon>
          <IconMessage stroke={1.5} size="20px" />
        </ListItemIcon>
        <ListItemText primary={<Typography variant="body2">Feedback</Typography>} />
      </ListItemButton>
      {userState.isLoggedIn ? (
        <ListItemButton sx={{ borderRadius: `${borderRadius}px` }} onClick={handleLogout}>
          <ListItemIcon>
            <IconLogout stroke={1.5} size="20px" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
        </ListItemButton>
      ) : (
        <ListItemButton sx={{ borderRadius: `${borderRadius}px` }} onClick={onClick}>
          <ListItemIcon>
            <IconLogin stroke={1.5} size="20px" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body2">Login</Typography>} />
        </ListItemButton>
      )}
      <Modal open={open} onClose={handleClose}>
        <ModalContent>
          <Feedback onCancel={handleClose} />
        </ModalContent>
      </Modal>
    </>
  );
}

LoginMenuOption.propTypes = {
  onClick: PropTypes.func
};
