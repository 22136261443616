import PropTypes from 'prop-types';
import useConfig from 'hooks/useConfig';
import { Button, Grid, Box, Typography } from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';

export default function MapLegend({ onCancel }) {
  const { baseUrls } = useConfig();

  const customScrollbarStyle = {
    '--scrollbar-height': '4px',
    '--scrollbar-width': '4px',
    overflowX: 'auto',
    overflowY: 'auto',
    marginBottom: '6px',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    '&::-webkit-scrollbar': {
      width: 'var(--scrollbar-width)',
      height: 'var(--scrollbar-height)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '2px'
    }
  };

  return (
    <Box
      sx={{
        height: '100%',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflowY: 'hidden',
        padding: '25px'
      }}
    >
      <Typography variant="h3">Map Legend</Typography>

      <Box sx={customScrollbarStyle}>
        <Box style={{ display: 'flex', flexShrink: 0 }}>
          <Box style={{ width: '200px', flexShrink: 0 }}>
            <img
              src={baseUrls.mapLegendImageLink.multiyearList}
              alt="vnf multiyear"
              style={{ width: '95%' }}
            />
            <img
              src={baseUrls.mapLegendImageLink.multiyearTemp}
              alt="vnf multiyear"
              style={{ width: '95%' }}
            />
          </Box>
          <Box style={{ width: '200px', flexShrink: 0 }}>
            <img src={baseUrls.mapLegendImageLink.catalogList} alt="vnf" style={{ width: '95%' }} />
            <img src={baseUrls.mapLegendImageLink.catalogTemp} alt="vnf" style={{ width: '95%' }} />
          </Box>
          {/* To add a third image , uncomment this section. . */}
          {/* <Box style={{ width: '200px', flexShrink: 0 }}>
            <img src={baseUrls.mapLegendImageLink.catalogList} alt="vnf" style={{ width: '95%' }} />
            <img src={baseUrls.mapLegendImageLink.catalogTemp} alt="vnf" style={{ width: '95%' }} />
          </Box> */}
        </Box>
      </Box>
      <Grid container justifyContent="center" item>
        <Grid item>
          <AnimateButton>
            <Button variant="outlined" type="reset" onClick={onCancel}>
              Close
            </Button>
          </AnimateButton>
        </Grid>
      </Grid>
    </Box>
  );
}

MapLegend.propTypes = {
  onCancel: PropTypes.func
};
