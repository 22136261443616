/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Box, InputAdornment, Autocomplete, TextField, Popper } from '@mui/material';
import { useSelector, useDispatch } from 'store';
import { shouldForwardProp } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import { openSnackbar } from 'store/slices/snackbar';
import {
  setFilterData,
  setSearchEnabledWithDelay,
  remoteSearchFilter
} from 'store/slices/searchFilter';
import { ContextEnum, setCurrentContext, setSelectedEmitterProps } from 'store/slices/dashboard';
import { getLatLongValidation } from 'utils/getLatLongValidation';
import { handleMarker, emitterClickHandler } from 'utils/layerUtils';

const createEventFromLatLng = (lat, lon) => ({
  features: [
    {
      id: Math.random().toString(36).substr(2, 9),
      geometry: {
        type: 'Point',
        coordinates: [lon, lat]
      }
    }
  ]
});

const getSelectedLayerText = (textId, layers) => {
  const selectedLayer = layers.find((layer) => layer.id === textId);

  if (selectedLayer) {
    return selectedLayer.name;
  }

  return null;
};

const getConfig = (data, id) => {
  const foundConfig = data.find((item) => item.config.id === id);
  return foundConfig ? foundConfig.config : null;
};

function formatDistance(distance, mapUnits) {
  if (mapUnits === 'km') {
    if (distance < 1) {
      return `${Math.round(distance * 1000)} meters`;
    } else {
      return `${distance.toFixed(2)} km`;
    }
  } else {
    if (distance < 1) {
      return `${Math.round(distance * 5280)} feet`;
    } else {
      return `${(distance * 0.621371).toFixed(2)} mi`;
    }
  }
}

const OutlineInputStyle = styled(TextField, { shouldForwardProp })(({ theme, focusedwidth }) => ({
  width: 230,
  transition: 'width 0.3s',
  paddingLeft: 16,
  paddingRight: 16,
  '& input': {
    background: 'transparent !important',
    paddingLeft: '4px !important',
    boxShadow: 'none !important'
  },
  [theme.breakpoints.down('lg')]: {
    width: 250
  },
  [theme.breakpoints.down('md')]: {
    width: 250,
    background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
  },
  [theme.breakpoints.down(670)]: {
    width: 200,
    background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
  },
  '&.focused': {
    width: focusedwidth
  }
}));

const CustomPopper = React.memo(({ anchorEl, style, ...other }) => {
  if (!anchorEl || !(anchorEl instanceof Element)) {
    return null;
  }

  return (
    <Popper
      {...other}
      style={{
        ...style,
        width: `${anchorEl.clientWidth}px`,
        boxShadow: 'none'
      }}
      anchorEl={anchorEl}
    />
  );
});

CustomPopper.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  style: PropTypes.object
};

const SearchSection = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [value, setValue] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [saveTimeout, setSaveTimeout] = useState(null);
  const { selectedEmitterProps } = useSelector((state) => state.dashboard);
  const { searchSelected, filterData } = useSelector((state) => state.searchFilter);
  const { map, unit } = useSelector((state) => state.map);
  const { layers } = useSelector((state) => state.layers);
  const [enabledString, setEnabledString] = useState([]);

  const autocompleteInputRef = useRef(null);

  const handleAlert = (text) => {
    dispatch(
      openSnackbar({
        open: true,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        message: text,
        variant: 'topCustomPosition'
      })
    );
  };

  useEffect(() => {
    const enabledLayers = layers?.filter((layer) => layer.enabled)?.map((layer) => layer.id) || [];
    setEnabledString(enabledLayers);
  }, [layers]);

  useEffect(() => {
    if (Object.keys(value).length > 0 && map) {
      map.jumpTo({
        center: [value.location[1], value.location[0]],
        zoom: process.env.REACT_APP_SEARCH_JUMP_ZOOM
          ? parseInt(process.env.REACT_APP_SEARCH_JUMP_ZOOM, 10)
          : 10
      });
      setValue({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, map, value]);

  const handleInputFocus = () => {
    setIsFocused(true);
    dispatch(setSearchEnabledWithDelay(true));
  };

  const handleSetDetail = (properties) => {
    if (properties) {
      dispatch(setSelectedEmitterProps(properties));
      dispatch(setCurrentContext(ContextEnum.Details));
    }
  };

  const handleInputBlur = () => {
    setIsFocused(false);
    dispatch(setSearchEnabledWithDelay(false));
  };

  const handleSearchCall = async (input, selectedLayers) => {
    if (saveTimeout) {
      clearTimeout(saveTimeout);
      setSaveTimeout(null);
    }

    setSaveTimeout(
      setTimeout(async () => {
        const trimmedInput = input.trim();

        if (trimmedInput === '') {
          dispatch(setFilterData([]));
        } else {
          const isValidLatLong = getLatLongValidation(input);
          if (isValidLatLong.length === 2) {
            const latLon = `${isValidLatLong[0]} ${isValidLatLong[1]}`;
            dispatch(remoteSearchFilter(latLon, selectedLayers));
          } else {
            const searchInput = trimmedInput.replace('#', '');
            dispatch(remoteSearchFilter(searchInput, selectedLayers));
          }
        }

        setSaveTimeout(null);
      }, 500)
    );
  };

  return (
    <>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        PopperComponent={CustomPopper}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            const isValidInput = getLatLongValidation(inputValue);
            if (isValidInput.length > 0) {
              setInputValue('');
              setValue({ location: isValidInput });
              const shouldPlaceMarker = process.env.REACT_APP_PLACE_MARKER === 'true';
              if (shouldPlaceMarker && map) {
                const event = createEventFromLatLng(isValidInput[0], isValidInput[1]);
                const config = getConfig(layers, enabledString[0]);
                handleMarker(event, map, config, null);
              }
              autocompleteInputRef.current.blur();
            } else {
              handleAlert(
                'Your input is not valid. Search Lat Lon or Site ID. Please check and try again.'
              );
            }
          }
        }}
        options={searchSelected ? filterData : []}
        getOptionLabel={(options) =>
          options.placename || Object.keys(value).length > 0 ? '' : options
        }
        filterOptions={(options) => options}
        renderOption={(props, option) => (
          <li {...props} key={`${option.id}-${option.placename}`}>
            <Box
              sx={{
                flexGrow: 1,
                '& span': {
                  color: theme.palette.mode === 'light' ? '#586069' : '#8b949e'
                }
              }}
            >
              {option.placename}
              <br />
              <span>ID: #{option.id}</span>

              {option.distance && (
                <>
                  <br />
                  <span>Distance from location: {formatDistance(option.distance, unit)}</span>
                </>
              )}

              {enabledString.length > 1 && (
                <>
                  <br />
                  <span style={{ fontStyle: 'italic' }}>
                    {getSelectedLayerText(option.layer, layers)}
                  </span>
                </>
              )}
            </Box>
          </li>
        )}
        onChange={(event, newValue) => {
          if (event.key !== 'Enter') {
            if (newValue && newValue.location && newValue.location.length > 0) {
              setValue(newValue);
              if (newValue.id !== selectedEmitterProps?.id) {
                const selectedLayer = newValue.layer ? newValue.layer : enabledString[0];
                const config = getConfig(layers, selectedLayer);
                const sourceId = config.id;
                const sourceLayer = config.sourceLayer;
                map.once('idle', () => {
                  const selectedFeature = map.querySourceFeatures(sourceId, {
                    sourceLayer,
                    filter: ['==', 'id', Number(newValue.id)]
                  });
                  if (selectedFeature.length === 1) {
                    const event = { features: selectedFeature };
                    emitterClickHandler(event, config, map, '', handleSetDetail);
                  }
                });
              }

              autocompleteInputRef.current.blur();
            }
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          handleSearchCall(newInputValue, enabledString);
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <OutlineInputStyle
            {...params}
            focusedwidth={isFocused ? 300 : 230}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            placeholder="Search"
            size="small"
            className={isFocused ? 'focused' : ''}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            inputRef={autocompleteInputRef}
          />
        )}
      />
    </>
  );
};

export default SearchSection;
