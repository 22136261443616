import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, CircularProgress, Grid, TextField, Box, Typography } from '@mui/material';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { useFormik } from 'formik';

import AnimateButton from 'ui-component/extended/AnimateButton';
import { axiosApi } from 'utils/axios';
import { gridSpacing } from 'store/constant';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch, useSelector } from 'store';
import { ContextEnum, setCurrentContext } from 'store/slices/dashboard';

import { IconSend } from '@tabler/icons';

/**
 * 'Enter your email'
 * yup.string Expected 0 arguments, but got 1 */
const validationSchema = yup.object({
  emailInstant: yup.string().email('Enter a valid email').required('Email is required'),
  messageInstant: yup.string().required('Message is required')
});

export default function Feedback({ onCancel }) {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);
  const dashboardState = useSelector((state) => state.dashboard);

  const formik = useFormik({
    initialValues: {
      nameValue: name,
      emailInstant: email,
      messageInstant: message
    },
    validationSchema,
    onSubmit: async (e) => {
      let toastMessage;
      let toastColor;
      try {
        setLoading(true);
        const res = await axiosApi.post('/feedback', {
          name: e.nameValue,
          email: e.emailInstant,
          message: e.messageInstant,
          emitterId: dashboardState.selectedEmitterProps?.id || null
        });
        setLoading(false);
        if (res.status === 200) {
          onCancel();
          setName('');
          setEmail('');
          setMessage('');
          toastMessage =
            'The Earth Observation Group has received your feedback and appreciates your support';
          toastColor = 'success';
          dispatch(setCurrentContext(ContextEnum.Overview));
        } else {
          toastMessage = 'Error sending message, please try again..';
          toastColor = 'error';
        }
        dispatch(
          openSnackbar({
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            open: true,
            message: toastMessage,
            variant: 'alert',
            alert: {
              color: toastColor
            },
            close: true
          })
        );
      } catch (err) {
        setLoading(false);
        dispatch(
          openSnackbar({
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            open: true,
            message: 'Unknown error',
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: true
          })
        );
        console.log('Catch Error:', err);
      }
    }
  });

  const handleOnChange = (token) => {
    setRecaptcha(token);
  };

  return (
    <Box
      style={{
        height: '100%',
        padding: '25px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Typography variant="h3">Feedback</Typography>
      <Typography variant="body1" style={{ padding: '10px' }}>
        Please provide any feedback you have, including suggestions for the web map, comments on web
        map functionality, and any information you have on specific IR emitter or flaring sites
        (site ownership, mislabeled types, etc.).
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={gridSpacing}>
          {dashboardState.selectedEmitterProps && (
            <Grid item xs={12}>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Please provide feedback about emitter #{dashboardState.selectedEmitterProps.id}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Enter Name (optional)"
              id="nameValue"
              name="nameValue"
              value={formik.values.nameValue}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="emailInstant"
              name="emailInstant"
              label="Enter Email"
              value={formik.values.emailInstant}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.emailInstant && Boolean(formik.errors.emailInstant)}
              helperText={formik.touched.emailInstant && formik.errors.emailInstant}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={7}
              id="messageInstant"
              name="messageInstant"
              label="Enter Message"
              value={formik.values.messageInstant}
              onChange={formik.handleChange}
              error={formik.touched.messageInstant && Boolean(formik.errors.messageInstant)}
              helperText={formik.touched.messageInstant && formik.errors.messageInstant}
              autoComplete="family-name"
            />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-around"
              spacing={2}
            >
              <Grid item>
                <AnimateButton>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Button variant="contained" type="submit" disabled={recaptcha === null}>
                      <IconSend strokeWidth="1.5" size="20px" />
                      Send
                    </Button>
                  )}
                </AnimateButton>
              </Grid>
              <Grid item>
                <AnimateButton>
                  <Button variant="outlined" type="reset" onClick={onCancel}>
                    Cancel
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

Feedback.propTypes = {
  onCancel: PropTypes.func
};
