const chartData = {
  height: 480,
  options: {
    chart: {
      // id: 'multi-line',
      id: 'bar-chart'
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%'
      }
    },
    xaxis: {
      type: 'numeric',
      min: 0,
      max: 12,
      tickAmount: 6,
      title: {
        text: 'Wavelength, um',
        style: {
          color: 'black',
          fontSize: '15px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 200,
          cssClass: 'apexcharts-yaxis-title'
        }
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        height: 1,
        width: '100%',
        offsetX: 0,
        offsetY: 0
      }
    },
    yaxis: {
      min: 0,
      max: 10,
      tickAmount: 5,
      axisBorder: {
        show: true,
        color: '#78909C',
        offsetX: 0,
        offsetY: 0
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        width: 6,
        offsetX: 0,
        offsetY: 0
      }
      // tooltip: {
      //     enabled: true,
      //     shared: false,
      //     theme: 'dark',
      //     onDatasetHover: { highlightDataSeries: true },
      //     fillSeriesColor: true
      // }
    },
    colors: ['#364be3', '#d91c4e', '#1fd91c', '#40a3e6', '#d91c4e', '#090a0a'],
    stroke: {
      width: 3,
      curve: 'smooth',
      dashArray: [0, 0]
    },
    legend: {
      show: true,
      fontFamily: `'Roboto', sans-serif`,
      position: 'bottom',
      offsetX: 20,
      labels: {
        useSeriesColors: false
      },
      markers: {
        width: 16,
        height: 16,
        radius: 5
      },
      itemMargin: {
        horizontal: 15,
        vertical: 8
      }
    },
    markers: {
      size: [7, 7, 7, 3, 3, 3],
      shape: 'circle',
      hover: {
        size: 8
      }
      // discrete: getDiscretes()
    },
    fill: {
      type: [null, null, null, 'gradient', 'gradient', 'gradient'],
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [10, 80, 100]
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: false
    }
  },
  series: [
    {
      name: 'no detection',
      type: 'scatter',
      data: [
        { x: 0.6, y: 0.2 },
        { x: 3, y: 3 },
        { x: 1.08, y: 5 },
        { x: 2, y: 5 },
        { x: 10, y: 5 },
        { x: 8, y: 0.6 }
      ]
    },
    {
      name: 'detection',
      type: 'scatter',
      data: [
        { x: 0.3, y: 0.5 },
        { x: 1.5, y: 0 },
        { x: 1.7, y: 0.2 },
        { x: 3.4, y: 1 }
      ]
    },
    {
      name: 'used as constraint',
      type: 'scatter',
      data: [
        { x: 6, y: 3 },
        { x: 6, y: 5 },
        { x: 10, y: 6.3 },
        { x: 9, y: 5 }
      ]
    },
    {
      name: 'dual curve model',
      type: 'area',
      data: [
        // [0.95, 20000], [1.05, 1000]
        { x: 0, y: 0 },
        { x: 3, y: 1 },
        { x: 6, y: 0 }
      ]
    },
    {
      name: 'IR source',
      type: 'area',
      data: [
        { x: 0, y: 0 },
        { x: 2, y: 0.2 },
        { x: 7, y: 0 },
        { x: 11, y: 0 }
      ]
    },
    {
      name: 'background',
      type: 'area',
      data: [
        // [0.95, 20000], [1.05, 1000]
        { x: 0, y: 0 },
        { x: 2, y: 0 },
        { x: 8, y: 6 },
        { x: 11, y: 4 }
      ]
    }
  ]
};
export default chartData;
