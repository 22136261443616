import { createSlice } from '@reduxjs/toolkit';

export const ContextEnum = {
  Overview: 'Overview',
  Edit: 'Edit',
  Filter: 'Filters',
  Details: 'Details',
  Layers: 'Layers',
  Backgrounds: 'Backgrounds'
};

const initialState = {
  currentContext: ContextEnum.Overview,
  selectedEmitterProps: null,
  selectionToolCoordinates: null
};

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setCurrentContext(state, action) {
      state.currentContext = action.payload;
    },
    setSelectedEmitterProps(state, action) {
      state.selectedEmitterProps = action.payload;
    },
    setSelectionToolCoordinates(state, action) {
      state.selectionToolCoordinates = action.payload;
    }
  }
});

export default dashboard.reducer;
export const { setCurrentContext, setSelectedEmitterProps, setSelectionToolCoordinates } =
  dashboard.actions;
