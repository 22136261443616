import * as turf from '@turf/turf';
import {
  getPolygonFeatures,
  removeDetectionsLayerIf,
  removePulseLayerIf,
  removePulseLayerBufferIf
} from 'utils/layerUtils';

const dotPointBufferLayerRingConfig = {
  dot: {
    id: 'layer-with-pulsing-dot-buffer-ring',
    source: 'dot-point-buffer',
    type: 'line',
    paint: {
      'line-color': '#000000',
      'line-width': 1
    }
  },
  random: {
    id: 'random-layer-with-pulsing-dot-buffer-ring',
    source: 'random-dot-point-buffer',
    type: 'line',
    paint: {
      'line-color': '#000000',
      'line-width': 1
    }
  }
};

const dotPointBufferLayerConfig = {
  dot: {
    id: 'layer-with-pulsing-dot-buffer',
    source: 'dot-point-buffer',
    type: 'fill',
    paint: {
      'fill-color': 'grey',
      'fill-opacity': 0.1
    }
  },
  random: {
    id: 'random-layer-with-pulsing-dot-buffer',
    source: 'random-dot-point-buffer',
    type: 'fill',
    paint: {
      'fill-color': 'grey',
      'fill-opacity': 0.1
    }
  }
};

const dotPointLayerConfig = {
  dot: {
    id: 'layer-with-pulsing-dot',
    type: 'symbol',
    source: 'dot-point',
    layout: {
      'icon-image': 'pulsing-dot'
    }
  },
  random: {
    id: 'random-layer-with-pulsing-dot',
    type: 'symbol',
    source: 'random-dot-point',
    bufferId: 'random-layer-with-pulsing-dot-buffer',
    bufferSource: 'random-dot-point-buffer',
    layout: {
      'icon-image': 'random-pulsing-dot'
    }
  }
};

const addPulse = (map, lat, lon, pulsingDot /* , layerId */, type = 'dot', draw = null) => {
  const dotPointSource = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [lon, lat]
          }
        }
      ]
    }
  };
  if (!map.hasImage(dotPointLayerConfig[type].layout['icon-image'])) {
    map.addImage(dotPointLayerConfig[type].layout['icon-image'], pulsingDot, { pixelRatio: 2 });
  }
  if (map.getSource(dotPointLayerConfig[type].source) === undefined) {
    map.addSource(dotPointLayerConfig[type].source, dotPointSource);
  }
  if (map.getLayer(dotPointLayerConfig[type].id) === undefined) {
    // map.addLayer(dotPointLayerConfig[type], layerId);
    map.addLayer(dotPointLayerConfig[type]);
    map.on('mouseenter', dotPointLayerConfig[type].id, () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', dotPointLayerConfig[type].id, () => {
      map.getCanvas().style.cursor = '';
    });
    if (type === 'random') {
      map.on('click', dotPointLayerConfig.random.id, () => {
        if (draw) {
          const featureCollection = getPolygonFeatures(draw);
          if (featureCollection) {
            draw.set(featureCollection);
          } else {
            draw.deleteAll();
          }
        }
        removeDetectionsLayerIf(map, type);
        removePulseLayerIf(map, type);
        removePulseLayerBufferIf(map, type);
      });
    }
  }
};

const addPulseBuffer = (map, latLon /* , layerId */, type) => {
  const point = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: latLon
    }
  };
  const bufferDistanceInMeters = process.env.REACT_APP_DETECTION_LAYER_BUFFER
    ? parseInt(process.env.REACT_APP_DETECTION_LAYER_BUFFER, 10)
    : 500;
  const bufferedPoint = turf.buffer(point, bufferDistanceInMeters, { units: 'meters' });

  const dotPointBufferSource = {
    type: 'geojson',
    data: bufferedPoint
  };

  if (map.getSource(dotPointBufferLayerConfig[type].source) === undefined) {
    map.addSource(dotPointBufferLayerConfig[type].source, dotPointBufferSource);
  }

  if (map.getLayer(dotPointBufferLayerConfig[type].id) === undefined) {
    // map.addLayer(dotPointLayerConfig[type], layerId);
    map.addLayer(dotPointBufferLayerConfig[type], dotPointLayerConfig[type].id);
  }

  if (map.getLayer(dotPointBufferLayerRingConfig[type].id) === undefined) {
    // map.addLayer(dotPointLayerConfig[type], layerId);
    map.addLayer(dotPointBufferLayerRingConfig[type], dotPointLayerConfig[type].id);
  }
};

export {
  dotPointBufferLayerConfig,
  dotPointBufferLayerRingConfig,
  dotPointLayerConfig,
  addPulse,
  addPulseBuffer
};
