import { createSlice } from '@reduxjs/toolkit';

import {
  methaneEqLimits,
  detectFreqLimits,
  temperatureLimits,
  radiantHeatLimits
} from 'config/filters/rangeFilters.json';
import { typeFilters, categoryFilters, countries } from 'config/filters';

const loadTypeFilterList = () =>
  typeFilters
    .sort((a, b) => a.label > b.label)
    .map((type) => ({
      ...type,
      checked: false
    }));

const loadCategoryList = () =>
  categoryFilters
    .sort((a, b) => a.label > b.label)
    .map((category) => ({
      ...category,
      checked: false
    }));

const loadCountryList = () =>
  countries.map((country) => ({
    name: country.name,
    code: country['country-code'],
    alpha3: country['alpha-3']
  }));

const initialState = {
  typeFilters: loadTypeFilterList(),
  categoryFilters: loadCategoryList(),
  countries: loadCountryList(),
  selectedCountry: null,
  temperature: [temperatureLimits.min, temperatureLimits.max],
  radiantHeat: [radiantHeatLimits.min, radiantHeatLimits.max],
  methaneEq: [methaneEqLimits.min, methaneEqLimits.max],
  detectFreq: [detectFreqLimits.min, detectFreqLimits.max]
};

const filters = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    toggleTypeFilter(state, action) {
      const index = state.typeFilters.findIndex((filter) => filter.label === action.payload.label);
      state.typeFilters[index].checked = !state.typeFilters[index].checked;
    },
    toggleCategoryFilter(state, action) {
      const index = state.categoryFilters.findIndex(
        (filter) => filter.label === action.payload.label
      );
      state.categoryFilters[index].checked = !state.categoryFilters[index].checked;
    },
    setCountry(state, action) {
      state.selectedCountry = action.payload;
    },
    setTemperature(state, action) {
      state.temperature = action.payload;
    },
    setRadiantHeat(state, action) {
      state.radiantHeat = action.payload;
    },
    setMethaneEq(state, action) {
      state.methaneEq = action.payload;
    },
    setDetectFreq(state, action) {
      state.detectFreq = action.payload;
    },
    clearAll(state) {
      state.typeFilters = initialState.typeFilters;
      state.countries = initialState.countries;
      state.selectedCountry = initialState.selectedCountry;
      state.categoryFilters = initialState.categoryFilters;
      state.temperature = initialState.temperature;
      state.radiantHeat = initialState.radiantHeat;
      state.methaneEq = initialState.methaneEq;
      state.detectFreq = initialState.detectFreq;
    },
    setQueryFilters(state, action) {
      const { category, country, detectFreq, methaneEq, radiantHeat, temperature, type } =
        action.payload;
      if (category) {
        state.categoryFilters = categoryFilters
          .sort((a, b) => a.label > b.label)
          .map((categoryItem) => ({
            ...categoryItem,
            checked: categoryItem.values.some((item) => category.includes(item))
          }));
      }
      if (country && state.countries.find((x) => x.name === country)) {
        state.selectedCountry = country;
      }
      if (
        detectFreq &&
        !!Object.getOwnPropertyDescriptor(detectFreq, 'min') &&
        !!Object.getOwnPropertyDescriptor(detectFreq, 'max')
      ) {
        state.detectFreq = [detectFreq.min, detectFreq.max];
      }
      if (
        methaneEq &&
        !!Object.getOwnPropertyDescriptor(methaneEq, 'min') &&
        !!Object.getOwnPropertyDescriptor(methaneEq, 'max')
      ) {
        state.methaneEq = [methaneEq.min, methaneEq.max];
      }
      if (
        radiantHeat &&
        !!Object.getOwnPropertyDescriptor(radiantHeat, 'min') &&
        !!Object.getOwnPropertyDescriptor(radiantHeat, 'max')
      ) {
        state.radiantHeat = [radiantHeat.min, radiantHeat.max];
      }
      if (
        temperature &&
        !!Object.getOwnPropertyDescriptor(temperature, 'min') &&
        !!Object.getOwnPropertyDescriptor(temperature, 'max')
      ) {
        state.temperature = [temperature.min, temperature.max];
      }
      if (type) {
        state.typeFilters = typeFilters
          .sort((a, b) => a.label > b.label)
          .map((typeItem) => ({
            ...typeItem,
            checked: typeItem.types.some((item) => type.includes(item))
          }));
      }
    }
  }
});

export default filters.reducer;
export const {
  toggleTypeFilter,
  toggleCategoryFilter,
  setCountry,
  setQueryFilters,
  setTemperature,
  setRadiantHeat,
  setMethaneEq,
  setDetectFreq,
  clearAll
} = filters.actions;
