const getCountryNameFromIso = (countries, iso, COUNTRY) => {
  // eslint-disable-next-line no-debugger
  if (COUNTRY) return COUNTRY;

  if (!iso) {
    return 'unknown';
  }
  const country = countries.find((country) => country.alpha3 === iso);
  return country.name;
};

export default getCountryNameFromIso;
