import PropTypes from 'prop-types';
import { Typography, Grid } from '@mui/material';
import ProfileHeaderCard from './ProfileHeaderCard';
import { gridSpacing } from 'store/constant';

const ChartHeader = ({ type, handelerSeries }) => (
  <Grid container spacing={gridSpacing}>
    <Grid item xs={12}>
      <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
        <Grid item xs zeroMinWidth>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container direction="column" spacing={3}>
                <Grid item container spacing={2} alignItems="center">
                  <Grid item>
                    <Typography variant="h2">Profile</Typography>
                  </Grid>
                  <Grid item xs zeroMinWidth />
                  <ProfileHeaderCard type={type} handelerSeries={handelerSeries} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

ChartHeader.propTypes = {
  type: PropTypes.string,
  handelerSeries: PropTypes.func
};

export default ChartHeader;
