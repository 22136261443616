import { createSlice } from '@reduxjs/toolkit';
import { openSnackbar } from 'store/slices/snackbar';
import { axiosApi } from 'utils/axios';

const initialState = {
  error: null,
  inputValue: '',
  filterData: [],
  loading: false,
  selectedItem: null,
  searchSelected: false
};

const searchFilter = createSlice({
  name: 'searchFilter',
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setFilterData(state, action) {
      state.filterData = action.payload;
    },
    setInputValue(state, action) {
      state.inputValue = action.payload;
    },
    setSelectedItem(state, action) {
      state.selectedItem = action.payload;
    },
    setSearchEnabled(state, action) {
      state.searchSelected = action.payload;
    }
  }
});

export default searchFilter.reducer;

export const { setError, setFilterData, setInputValue, setSelectedItem, setSearchEnabled } =
  searchFilter.actions;

export const setSearchEnabledWithDelay = (isEnabled) => async (dispatch) => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  dispatch(setSearchEnabled(isEnabled));
};

export const remoteSearchFilter = (inputValue, selectedLayers) => async (dispatch) => {
  try {
    const layerNames = selectedLayers.map((layer) => `layers[]=${layer}`);
    const url = `complete?q=${inputValue}&${layerNames.join('&')}`;
    const response = await axiosApi.get(url);
    dispatch(setFilterData(response.data));
  } catch (error) {
    console.log('errr', error);
    dispatch(setError(error));
    let message = error.message;
    if (error.message === 'Search string "q" is missing') {
      message = 'Your input is not valid. Search Lat Lon or Site ID. Please check and try again.';
    }
    dispatch(
      openSnackbar({
        open: true,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        message: message || 'Request issue, please double-check.',
        variant: 'topCustomPosition'
      })
    );
  }
};
