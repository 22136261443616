import { LOGO_URL_PATH } from 'config';
import Logo from 'ui-component/Logo';

const LogoSection = () => (
  <a href={LOGO_URL_PATH} target="_blank" rel="noreferrer">
    <Logo />
  </a>
);

export default LogoSection;
