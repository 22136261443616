import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Main from './MainChart';
import CloudStatus from './CloudStatus';
import { gridSpacing } from 'store/constant';

const TotalGrowthBarChart = ({ myseries, tempdata }) => (
  <Grid container spacing={gridSpacing}>
    <Grid item xs={12}>
      <Grid container alignItems="flex-start" justifyContent="space-between" spacing={gridSpacing}>
        <Grid item xs zeroMinWidth>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Main tempdata={tempdata} myseries={myseries} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1.5}>
          <CloudStatus tempdata={tempdata} />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

TotalGrowthBarChart.propTypes = {
  myseries: PropTypes.array,
  tempdata: PropTypes.object
};

export default TotalGrowthBarChart;
