import { useState } from 'react';
import { Box, Modal } from '@mui/material';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import LoginMenuOption from './LoginMenuOption';
import LoginComponent from './LoginComponent';

const height = 475;
const width = 600;

const ModalContent = styled(Box)`
  position: absolute;
  top: calc(50vh - ${height / 2}px);
  left: calc(50vw - ${width / 2}px);
  width: ${width}px;
  height: ${height}px;
  background: white;
  border-radius: 15px;
  outline: none;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;

const FormContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 0px;
  height: 100%;
`;

export default function LoginModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <LoginMenuOption onClick={handleOpen} />
      <Modal
        open={open}
        sx={{
          backdropFilter: 'blur(5px)'
        }}
        onClose={handleClose}
      >
        <ModalContent>
          <CloseIconContainer onClick={handleClose}>
            <CloseIcon />
          </CloseIconContainer>
          <FormContainer>
            <LoginComponent handleClose={handleClose} />
          </FormContainer>
        </ModalContent>
      </Modal>
    </>
  );
}
