import { useState } from 'react';
import { Grid, Typography } from '@mui/material';

import ChartHeader from './ChartHeader';
import ChartBody from './ChartBody';
import { gridSpacing } from 'store/constant';
import seriesData from './chart-data/series.json';
import tempData from './chart-data/temp.json';
import chartData from './chart-data/profile-chart-data';
import ChartFooter from './ChartFooter';

const Profile = () => {
  const [tempdata, settempdata] = useState(tempData.Today);
  const [myseries, setmyseries] = useState(chartData.series);
  const [type, setType] = useState('Today');
  const handelerSeries = (e) => {
    setType(e.target.innerText);
    setmyseries(seriesData[e.target.innerText]);
    settempdata(tempData[e.target.innerText]);
  };
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <ChartHeader type={type} handelerSeries={handelerSeries} />
      </Grid>
      <Grid item xs={12}>
        <ChartBody tempdata={tempdata} myseries={myseries} />
        <ChartFooter tempdata={tempdata} />
        <Grid item xs zeroMinWidth />
        <Grid item>
          <Typography sx={{ p: 0.55 }} ariant="h5" color="#000000">
            Source Information :
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profile;
