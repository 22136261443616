import { createRoot } from 'react-dom/client';
import DetectionsInfoTpl from 'ui-component/cards/DetectionsInfoTpl';
import { popup } from 'utils/layerUtils';

const detectionsLayerConfig = {
  dot: {
    id: 'detections-layer',
    type: 'circle',
    source: 'detections-source',
    paint: {
      'circle-color': '#001ab2',
      'circle-radius': 4
    }
  },
  random: {
    id: 'random-detections-layer',
    type: 'circle',
    source: 'random-detections-source',
    paint: {
      'circle-color': '#001ab2',
      'circle-radius': 4
    }
  }
};

const getPaint = (radius, color) => ({
  'circle-color': color,
  'circle-opacity': 0.6,
  'circle-radius': radius
});

const addDetections = (map, detectionsSource, style, layerId, type = 'dot') => {
  if (map.getSource(detectionsLayerConfig[type].source) === undefined) {
    map.addSource(detectionsLayerConfig[type].source, detectionsSource);
  }
  detectionsLayerConfig[type].paint =
    style?.circleColor && style?.circleRadius
      ? getPaint(style?.circleRadius, style?.circleColor)
      : detectionsLayerConfig[type].paint;
  detectionsLayerConfig[type].paint['circle-stroke-width'] = 1.5;
  if (style?.circleStrokeColor) {
    detectionsLayerConfig[type].paint['circle-stroke-color'] = style?.circleStrokeColor;
  }
  if (map.getLayer(detectionsLayerConfig[type].id) === undefined) {
    // map.addLayer(detectionsLayerConfig[type], layerId); // avoided this, as with the current layer loading the layer might be turned off so will cause error (did this to avoid registering multiple listeners to event)
    map.addLayer(detectionsLayerConfig[type]);
  }

  map.on('mouseenter', detectionsLayerConfig[type].id, (event) => {
    map.getCanvas().style.cursor = 'pointer';
    const { properties } = event.features[0];
    if (properties) {
      const placeHolder = document.createElement('div');
      const root = createRoot(placeHolder); // Use createRoot here
      root.render(<DetectionsInfoTpl properties={{ ...properties }} />);
      popup.setDOMContent(placeHolder).setLngLat(event.lngLat).addTo(map);
    }
  });

  map.on('mouseleave', detectionsLayerConfig[type].id, () => {
    map.getCanvas().style.cursor = '';
    popup.remove();
  });
};

export { detectionsLayerConfig, addDetections };
