import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

export default function DetectionsInfoTpl({ properties }) {
  const {
    Area_BB: areaBB,
    Date_Mscan: scanDate,
    ESF_BB: esfBB,
    Lat_GMTCO: lat,
    Lon_GMTCO: lon,
    RH: rh,
    Satellite: satellite,
    Temp_Bkg: tempBkg,
    t_mean: tMean,
    Temp_BB: tempBB
  } = properties;
  let { Cloud_Mask: cloudMask } = properties;
  cloudMask = cloudMask || null;
  return (
    <Stack>
      <b>DETECTION</b>
      {areaBB && (
        <span>
          Area <b>{areaBB}</b>
        </span>
      )}
      {cloudMask && (
        <span>
          Cloud Mask <b>{cloudMask}</b>
        </span>
      )}
      {scanDate && (
        <span>
          Scan Date <b>{scanDate}</b>
        </span>
      )}
      {esfBB && (
        <span>
          ESF <b>{esfBB}</b>
        </span>
      )}
      {rh && (
        <span>
          RH <b>{rh}</b>
        </span>
      )}
      {satellite && (
        <span>
          Satellite <b>{satellite}</b>
        </span>
      )}
      {tMean && (
        <span>
          Avg. Temperature <b>{parseInt(tMean, 10).toLocaleString()} K</b>
        </span>
      )}
      {tempBB && (
        <span>
          Temperature <b>{parseInt(tempBB, 10).toLocaleString()} K</b>
        </span>
      )}
      {tempBkg && parseInt(tempBkg, 10) !== 999999 && (
        <span>
          Temperature Bkg <b>{parseInt(tempBkg, 10).toLocaleString()} K</b>
        </span>
      )}
      {lat && (
        <span>
          <b>
            {lat}, {lon}
          </b>
        </span>
      )}
    </Stack>
  );
}

DetectionsInfoTpl.propTypes = {
  properties: PropTypes.object
};
