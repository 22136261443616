import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from './MenuList';

import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ window }) => {
  const theme = useTheme();
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const drawer = useMemo(
    () => (
      <PerfectScrollbar
        component="div"
        style={{
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <MenuList />
      </PerfectScrollbar>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [matchUpSm]
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component="nav" sx={{ width: '20px' }} aria-label="mailbox folders">
      {!matchUpSm && (
        <Drawer
          container={container}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          onClose={() => dispatch(openDrawer(!drawerOpen))}
          sx={{
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              background: theme.palette.background.default,
              color: theme.palette.text.primary,
              borderRight: 'none',
              top: '88px'
            }
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          {drawerOpen && drawer}
        </Drawer>
      )}
    </Box>
  );
};

Sidebar.propTypes = {
  window: PropTypes.object
};

export default memo(Sidebar);
