import PropTypes from 'prop-types';
import { useDispatch } from 'store';

// material-ui
import { Grid, Typography, Tooltip, IconButton } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { IconMessageShare } from '@tabler/icons';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { openSnackbar } from 'store/slices/snackbar';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';

const commonStyles = {
  bgcolor: 'background.paper',
  m: 0,
  border: 1
};

const Footer = ({ tempdata }) => {
  const { file, analizedDay } = tempdata;
  const dispatch = useDispatch();
  return (
    <Grid
      container
      alignItems="flex-start"
      spacing={0}
      sx={{
        ...commonStyles,
        borderColor: 'error.main',
        color: '#fff',
        '&:hover svg': {
          opacity: '1',
          transform: 'scale(1.1)'
        },
        borderRadius: 1
      }}
    >
      <Grid container>
        <Typography sx={{ p: 0.5 }} align="left" variant="h5" color="#000000">
          Analized on {analizedDay}
        </Typography>
      </Grid>
      <Grid container alignItems="center" spacing={0.5} sx={{ p: 0.1 }} item xs={12}>
        <Grid item>
          <Typography sx={{ color: '#F24E29' }}>
            <FolderIcon />
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ p: 0.55 }} ariant="h5" color="#000000">
            File : {file}
          </Typography>
        </Grid>
        <Grid item xs zeroMinWidth />
        <Grid item>
          <CopyToClipboard
            text={file}
            onCopy={() =>
              dispatch(
                openSnackbar({
                  open: true,
                  message: 'Text Copied',
                  variant: 'alert',
                  alert: {
                    color: 'success'
                  },
                  close: false,
                  anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  transition: 'SlideLeft'
                })
              )
            }
          >
            <Tooltip title="Copy">
              <IconButton size="medium" sx={{ px: 0 }}>
                <ContentCopyTwoToneIcon sx={{ fontSize: '1.1rem' }} />
              </IconButton>
            </Tooltip>
          </CopyToClipboard>
        </Grid>
        <Grid item>
          <Typography sx={{ color: '#6e6a6a' }}>
            <IconMessageShare strokeWidth="2.25" width="20" height="20" />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

Footer.propTypes = {
  tempdata: PropTypes.object
};

export default Footer;
