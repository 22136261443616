import axios from 'axios';

const axiosLocal = axios.create();
axiosLocal.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});
axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export { axiosLocal, axiosApi };
