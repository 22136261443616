import { useState } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Profile from './Chart/Profile';

export default function KPI2() {
  const [title] = useState('KPI 2');

  return (
    <MainCard title={<Typography variant="h3">KPI Demo: {title}</Typography>}>
      <Profile />
    </MainCard>
  );
}
