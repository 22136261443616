import { createSlice } from '@reduxjs/toolkit';
import countriesGeoJson from 'config/countries.geojson';
import { axiosApi } from 'utils/axios';

const initialState = {
  loading: false,
  aggregatedProfileBtn: false,
  geoJson: null,
  customizeGeoJson: null,
  downloadsAndResources: null,
  selectedIsoCOde: 'all',
  selectedType: 'all',
  chartSeries: [],
  filterTypes: [],
  chartLoading: false
};

const aggregatedProfile = createSlice({
  name: 'aggregatedProfile',
  initialState,
  reducers: {
    setLoading(state) {
      state.loading = !state.loading;
    },
    setAggregatedProfileBtn(state) {
      state.aggregatedProfileBtn = !state.aggregatedProfileBtn;
    },
    setgeojson(state, action) {
      state.geoJson = action.payload;
    },
    setSelectedISOandType(state, action) {
      const { iso, type } = action.payload;
      state.selectedIsoCOde = iso;
      state.selectedType = type;
    },
    setCustomizeGeoJsonType(state, action) {
      const { geoJson, typeData } = action.payload;

      const modifiedFeatures = geoJson.features.map((feature) => {
        const properties = { ...feature.properties };
        const iso = feature?.properties?.ADM0_A3;
        if (typeData.includes(iso)) {
          properties.isType = true;
        } else {
          const match = typeData.find((item) => item.includes(iso));
          if (match) {
            properties.ADM0_A3 = match;
          }
          properties.isType = !!match;
        }
        return {
          id: feature.id,
          properties,
          geometry: feature.geometry
        };
      });

      state.customizeGeoJson = {
        ...geoJson,
        features: modifiedFeatures
      };
    },
    setDownloadsAndResources(state, action) {
      state.downloadsAndResources = action.payload;
    },
    setAddChartSeries(state, action) {
      const { countryName, isoCode, data } = action.payload;

      if (isoCode === 'all') {
        if (data.length === 0) {
          state.chartSeries = [];
        } else {
          state.chartSeries = [
            {
              countryName: 'All',
              isoCode: 'all',
              data
            }
          ];
        }
      } else {
        const allIndex = state.chartSeries.findIndex((series) => series.isoCode === 'all');
        if (allIndex !== -1) {
          state.chartSeries.splice(allIndex, 1);
        }
        state.chartSeries.push({
          countryName,
          isoCode,
          data
        });
      }
    },
    setRemoveChartSeries(state, action) {
      const indexToRemove = action.payload;
      state.chartSeries.splice(indexToRemove, 1);
    },
    setFilterTypes(state, action) {
      state.filterTypes = action.payload;
    },
    setChartLoading(state, action) {
      state.chartLoading = action.payload;
    }
  }
});

export default aggregatedProfile.reducer;
export const {
  setAggregatedProfileBtn,
  setgeojson,
  setCustomizeGeoJsonType,
  setDownloadsAndResources,
  setSelectedISOandType,
  setLoading,
  setAddChartSeries,
  setRemoveChartSeries,
  setFilterTypes,
  setChartLoading
} = aggregatedProfile.actions;

export const loadGeoJson = () => async (dispatch) => {
  try {
    const response = await fetch(countriesGeoJson);
    const data = await response.json();
    dispatch(setgeojson(data));
  } catch (error) {
    console.error('Error fetching or parsing JSON:', error);
  }
};

export const loadCountryResources = (isoCode, type) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosApi.get(`aggregate-profile?country=${isoCode}&type=${type}`);
    dispatch(setDownloadsAndResources(response.data));
    dispatch(setSelectedISOandType({ isoCode, type }));
  } catch (error) {
    console.log('errr', error);
  }
  dispatch(setLoading());
};

export const loadCountryType = (geoJson, selectedType) => async (dispatch) => {
  try {
    const response = await axiosApi.get(`country?type=${selectedType}`);
    dispatch(setCustomizeGeoJsonType({ geoJson, typeData: response.data }));
  } catch (error) {
    console.log('errr', error);
  }
};

export const loadChartData =
  (countryName = 'all', isoCode = 'all', type) =>
  async (dispatch) => {
    dispatch(setChartLoading(true));
    try {
      const response = await axiosApi.get(
        `aggregate-profile/chart?country=${isoCode}&type=${type}`
      );
      dispatch(setAddChartSeries({ countryName, isoCode, data: response.data }));
    } catch (error) {
      console.log('errr', error);
    }
    dispatch(setChartLoading(false));
  };

export const loadFilterTypes = () => async (dispatch) => {
  try {
    const response = await axiosApi.get('aggregate-profile/types');
    dispatch(setFilterTypes(response.data));
  } catch (error) {
    console.log('err', error);
  }
};
