import { AuthRoles } from 'store/slices/user';
import { generateTypeStyle, temperatureStyle, energyStyle } from './styles';

const tileSource = `${process.env.REACT_APP_API_URL}/layers/vnf2023/{z}/{x}/{y}.mvt`;

const layerTypes = [
  'petrochem',
  'lng',
  'gas',
  'opp',
  'volcano',
  'midstream',
  'unknown',
  'flare',
  'new',
  'industrial',
  'landfill',
  'biomass',
  'refinery',
  'gpp'
];

const viirs2023Config = {
  id: 'viirs2023',
  name: '2023 Global Gas Flare Catalog',
  description: 'Toggle to enable/disable layer',
  enabled: false, // optional, will default to false if not set here
  requiredAuthLevel: AuthRoles.Public,
  tooltipText: 'Public dataset layer',
  layerType: 'symbol',
  resourceId: 'y2023',
  profileUrl: (id) =>
    `https://eogdata.mines.edu/wwwdata/hidden/vnf_profiles/profiles_2023/flare_${id}_y2023_vnf_dashboard.png`,
  csvUrl: (id) =>
    `https://eogdata.mines.edu/wwwdata/hidden/vnf_profiles/profiles_2023/flare_${id}_y2023_vnf_series.csv`,
  sourceLayer: 'vnf',
  tileSource,
  adminOnly: true,
  metadata: {
    labels: {
      '-': 'Unknown',
      downstream: 'Downstream Flare',
      flare: 'Upstream Oil',
      gas: 'Upstream Gas',
      gpp: 'Gas Processing',
      opp: 'Oil Processing',
      petrochem: 'Petrochemical',
      sawmill: 'Wood Processing',
      upstream: 'Upstream Flare',
      '400,1000': '400 - 1000 K',
      '1000,1200': '1000 - 1200 K',
      '1200,1400': '1200 - 1400 K',
      '1400,1600': '1400 - 1600 K',
      '1600,2500': '1600 - 2500 K',
      2500: '2500 K'
    },
    name: 'Gas Flare Global Catalog 2023'
  },
  layerSource: {
    attribution:
      '© <a href="https://eogdata.mines.edu/products/vnf/#credit" target="_blank">Earth Observation Group</a>',
    type: 'vector',
    tiles: [tileSource],
    minzoom: 1,
    maxzoom: 20
  },
  styles: {
    typeStyle: generateTypeStyle(layerTypes),
    temperatureStyle,
    energyStyle
  },
  paint: (radius, color) => ({
    'icon-color': color
  }),
  layout: (icon, size) => ({
    'icon-image': icon,
    'icon-size': size,
    'icon-allow-overlap': true
  }),
  sublayers: [
    {
      id: 'viirs2023-catalog-contour',
      layerType: 'line',
      metadata: {
        name: 'Site Contours',
        labels: {
          '#000': 'Outline (Login Req.)'
        }
      },
      sourceLayer: 'contour',
      paint: () => ({
        'line-color': '#000',
        'line-width': 1
      })
    }
  ]
};

export default viirs2023Config;
