import { createSlice } from '@reduxjs/toolkit';

import backgroundsConfig from 'config/backgroundsConfig.json';

const initialState = {
  bgSelected: backgroundsConfig[0]
};

const backgrounds = createSlice({
  name: 'backgrounds',
  initialState,
  reducers: {
    setBackground(state, action) {
      state.bgSelected = action.payload;
    }
  }
});

export default backgrounds.reducer;
export const { setBackground } = backgrounds.actions;
