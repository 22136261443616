// basename: only at build time to set, and Don't add '/' at end of BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/home/react/app'
import {
  detectFreqFilterConfig,
  methaneEqFilterConfig,
  radiantHeatFilterConfig
} from './filterConfig';

import urls from './urlsConfig';
import constantCustomMapValue from './constantCustomConfig';

export const BASE_PATH = '/giree';

export const DASHBOARD_PATH = '/giree/dashboard';
export const LOGO_URL_PATH = 'https://payneinstitute.mines.edu/eog/';

const config = {
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  navType: 'light', // light, dark
  presetColor: 'minesTheme', // default, theme1, theme2, theme3, theme4, theme5, theme6, minesTheme
  locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false,
  baseUrls: urls,
  detectFreqFilterConfig,
  constantCustomMapValue,
  methaneEqFilterConfig,
  radiantHeatFilterConfig
};

export default config;
