import { filterStyleColors } from 'utils/layerUtils';
import { serializeColors, serializeIcons } from './util';

const defaultTypeColor = '#800000';
const defaultIcon = 'unknown';

const typeStyleColors = [
  {
    name: 'biomass',
    color: '#413a54'
  },
  {
    name: 'cement',
    color: '#00FFFF'
  },
  {
    name: 'coal mine',
    color: '#0570b0'
  },
  {
    name: 'downstream',
    color: '#54278f'
  },
  {
    name: 'downstream flare',
    color: '#54278f'
  },
  {
    name: 'flare',
    color: '#fc4e2a'
  },
  {
    name: 'gas',
    color: '#f0307d'
  },
  {
    name: 'gpp',
    color: '#3551cc'
  },
  {
    name: 'greenhouse',
    color: '#00FF00'
  },
  {
    name: 'industrial',
    color: '#54B435'
  },
  {
    name: 'landfill',
    color: '#891c1c'
  },
  {
    name: 'lng',
    color: '#07f7eb'
  },
  {
    name: 'metallurgy',
    color: '#df65b0'
  },
  {
    name: 'midstream',
    color: '#d465f0'
  },
  {
    name: 'opp',
    color: '#16e0f2'
  },
  {
    name: 'petrochem',
    color: '#5f5443'
  },
  {
    name: 'refinery',
    color: '#f28816'
  },
  {
    name: 'sawmill',
    color: '#008080'
  },
  {
    name: 'unique',
    color: '#78392C'
  },
  {
    name: 'unknown',
    color: '#ff0000'
  },
  {
    name: 'upstream',
    color: '#fc4e2a'
  },
  {
    name: 'volcano',
    color: '#b506c9'
  },
  {
    name: 'whflare',
    color: '#fc4e2a'
  },
  {
    name: '-',
    color: '#e697d5'
  },
  {
    name: 'new',
    color: '#e697d5'
  }
];

const generateTypeStyle = (layerTypes) => {
  const styleColors = filterStyleColors(typeStyleColors, layerTypes);
  return {
    circleColor: ['match', ['get', 'type'], ...serializeColors(styleColors), defaultTypeColor],
    circleRadius: [
      'interpolate',
      ['linear'],
      ['zoom'],
      0, // constant size for zoom levels 0 to 7
      0.035,
      7,
      0.035,
      22, // gradually increase size up to 0.2 by zoom level 22
      0.2
    ],
    markerIcon: ['match', ['get', 'type'], ...serializeIcons(styleColors), defaultIcon]
  };
};

const energyStyle = {
  circleColor: '#FD7567',
  circleRadius: [
    'interpolate',
    ['linear'],
    ['coalesce', ['get', 'rh'], 0],
    0, // rh value
    4, // circle radius in pixels for anything more than the rh value above and less than the value below
    1, // rh value
    5, // circle radius in pixels for anything more than the rh value above and less than the value below
    2, // rh value
    6, // circle radius in pixels for anything more than the rh value above and less than the value below
    3, // rh value
    7, // circle radius in pixels for anything more than the rh value above and less than the value below
    4, // rh value
    8, // circle radius in pixels for anything more than the rh value above and less than the value below
    5, // rh value
    9, // circle radius in pixels for anything more than the rh value above and less than the value below
    10, // rh value
    12, // circle radius in pixels for anything more than the rh value above and less than the value below
    20, // rh value
    14, // circle radius in pixels for anything more than the rh value above and less than the value below
    30, // rh value
    16, // circle radius in pixels for anything more than the rh value above and less than the value below
    40, // rh value
    18, // circle radius in pixels for anything more than the rh value above and less than the value below
    50, // rh value
    20, // circle radius in pixels for anything more than the rh value above and less than the value below
    60, // rh value
    22 // circle radius in pixels for anything more than the rh value above and less than the value below
  ]
};

const temperatureStyle = {
  circleColor: [
    'step',
    ['coalesce', ['get', 't_mean'], 0],
    '#FFFFFF',
    400,
    '#8E67FD',
    1000,
    '#6891FD',
    1200,
    '#01E64D',
    1400,
    '#FDF569',
    1600,
    '#FD7567',
    2500,
    '#FF991A'
  ],
  circleRadius: [
    'interpolate',
    ['linear'],
    ['zoom'],
    12,
    4, // zoom >0 <=12
    13,
    7, // zoom >=13 <22
    22,
    12 // zoom >=22
  ],
  circleStrokeColor: [
    'step',
    ['coalesce', ['get', 't_mean'], 0],
    '#FFFFFF',
    400,
    '#3903d3',
    1000,
    '#033cd3',
    1200,
    '#018a2e',
    1400,
    '#d4c903',
    1600,
    '#d31603',
    2500,
    '#703e00'
  ]
};

export { defaultTypeColor, typeStyleColors, generateTypeStyle, temperatureStyle, energyStyle };
