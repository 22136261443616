import { useTheme } from '@mui/material/styles';
import { Box, Button, Tooltip } from '@mui/material';
import { dispatch, useSelector } from 'store';
import { setAggregatedProfileBtn } from 'store/slices/aggregatedProfiles';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function AggregatedProfileButton() {
  const theme = useTheme();
  const { aggregatedProfileBtn } = useSelector((state) => state.aggregatedProfile);

  const handleAggregatedProfile = () => {
    dispatch(setAggregatedProfileBtn());
  };

  return (
    <Tooltip title={aggregatedProfileBtn ? 'Back to Map' : 'Aggregated Profiles'}>
      <Box
        sx={{
          mr: 2,
          [theme.breakpoints.down('md')]: {
            ml: 1
          }
        }}
      >
        <Button
          onClick={handleAggregatedProfile}
          sx={{
            background: aggregatedProfileBtn
              ? theme.palette.primary.main
              : theme.palette.primary.light,
            color: aggregatedProfileBtn ? theme.palette.primary.light : theme.palette.primary.main,
            transition: 'all .2s ease-in-out',
            '&[aria-controls="menu-list-grow"],&:hover': {
              background: theme.palette.primary.main,
              color: theme.palette.primary.light
            }
          }}
        >
          {aggregatedProfileBtn ? <ArrowBackIcon /> : <HubOutlinedIcon />}
        </Button>
      </Box>
    </Tooltip>
  );
}
