import {
  methaneEqLimits,
  detectFreqLimits,
  temperatureLimits,
  radiantHeatLimits
} from 'config/filters/rangeFilters.json';

export const isFalsy = (value) =>
  [null, 'null', undefined, 'undefined', false, 'false', ''].includes(value);

export const methaneEqScaleFn = (x) => {
  const scaler = 10000;
  const scaledValue = 2 ** x / scaler;
  return scaledValue;
};

export const radiantHeatScaleFn = (x) => {
  const scaler = 2250;
  const scaledValue = 2 ** x / scaler;
  return scaledValue;
};

export const getActiveTypeFilters = (filterState) => {
  const typeFilters = [];
  filterState.typeFilters
    .filter((type) => type.checked)
    .forEach((activeFilter) => typeFilters.push(...activeFilter.types));
  return typeFilters;
};

export const getActiveTypeFiltersCount = (filterState) =>
  filterState.typeFilters.filter((type) => type.checked).length;

export const getActiveTypeLayerCount = (layersState) =>
  layersState.layers.filter((layer) => layer.enabled).length;

export const getActiveCategoryFilters = (filterState) => {
  const categoryFilters = [];
  filterState.categoryFilters
    .filter((category) => category.checked)
    .forEach((activeFilter) => categoryFilters.push(...activeFilter.values));
  return categoryFilters;
};

export const getActiveCategoryFiltersCount = (filterState) =>
  filterState.categoryFilters.filter((category) => category.checked).length;

const clearFilters = (layers, map) => {
  if (map) {
    layers.forEach((layer) => {
      if (map.getLayer(layer.id)) {
        map.setFilter(layer.id, null);
      }
    });
  }
};

export const setFilters = (filterState, layers, map) => {
  clearFilters(layers, map);
  let countryFilter = null;
  const { detectFreq, methaneEq, radiantHeat, selectedCountry, temperature } = filterState;
  if (filterState && selectedCountry && selectedCountry.alpha3) {
    countryFilter = ['==', ['get', 'iso'], selectedCountry.alpha3];
  }
  let typeFilter = null;
  const activeTypeFilters = getActiveTypeFilters(filterState);
  if (activeTypeFilters.length) {
    typeFilter = ['in', ['get', 'type'], ['literal', [...activeTypeFilters]]];
  }

  let categoryFilter = null;
  const activeCategoryFilters = getActiveCategoryFilters(filterState);
  if (activeCategoryFilters.length) {
    categoryFilter = ['in', ['get', 'category'], ['literal', [...activeCategoryFilters]]];
  }

  // coalesce is used to cast any nulls to 0
  const temperatureFilter = [
    'all',
    ['>=', ['coalesce', ['get', 't_mean'], 0], temperature[0]],
    ['<=', ['coalesce', ['get', 't_mean'], 0], temperature[1]]
  ];
  const radiantHeatFilter = [
    'all',
    ['>=', ['coalesce', ['get', 'rh'], 0], radiantHeatScaleFn(radiantHeat[0])],
    ['<=', ['coalesce', ['get', 'rh'], 0], radiantHeatScaleFn(radiantHeat[1])]
  ];
  const methaneEqFilter = [
    'all',
    ['>=', ['coalesce', ['get', 'bcm'], 0], methaneEqScaleFn(methaneEq[0])],
    ['<=', ['coalesce', ['get', 'bcm'], 0], methaneEqScaleFn(methaneEq[1])]
  ];
  const detectFreqFilter = [
    'all',
    ['>=', ['coalesce', ['get', 'pct'], 0], detectFreq[0] / 100],
    ['<=', ['coalesce', ['get', 'pct'], 0], detectFreq[1] / 100]
  ];

  const layersFilter = ['all'];

  if (!(temperature[0] === temperatureLimits.min && temperature[1] === temperatureLimits.max)) {
    layersFilter.push(temperatureFilter);
  }

  if (!(radiantHeat[0] === radiantHeatLimits.min && radiantHeat[1] === radiantHeatLimits.max)) {
    layersFilter.push(radiantHeatFilter);
  }

  if (!(methaneEq[0] === methaneEqLimits.min && methaneEq[1] === methaneEqLimits.max)) {
    layersFilter.push(methaneEqFilter);
  }

  if (!(detectFreq[0] === detectFreqLimits.min && detectFreq[1] === detectFreqLimits.max)) {
    layersFilter.push(detectFreqFilter);
  }

  if (countryFilter) {
    layersFilter.push(countryFilter);
  }
  if (typeFilter) {
    layersFilter.push(typeFilter);
  }
  if (categoryFilter) {
    layersFilter.push(categoryFilter);
  }

  if (layersFilter.length > 1) {
    layers.forEach((layer) => {
      if (layer.enabled) {
        if (map.getLayer(layer.id)) {
          map.setFilter(layer.id, null);
          if (layersFilter.length > 1) {
            map.setFilter(layer.id, layersFilter);
          }
        }
      }
    });
  }
};
