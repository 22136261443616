/* eslint-disable no-plusplus */
import * as turf from '@turf/turf';
import { setRemoveMeasureData, setAddMeasureData } from 'store/slices/layers';

function updateDrawMeasure(selectedDraw, dispatch) {
  const features = { data: [] };
  const drawnFeatures = selectedDraw;
  drawnFeatures.forEach((feature) => {
    if (feature.geometry.type === 'LineString') {
      features.id = feature.id;
      const segments = turf.lineSegment(feature);
      segments.features.forEach((segment) => {
        const centroid = turf.centroid(segment);
        const lineLengthKm = turf.length(segment) * 1000;
        const lineLengthMile = turf.length(segment, { units: 'miles' });

        let measurementKmMeter;
        if (lineLengthKm >= 1000) {
          measurementKmMeter = `${(lineLengthKm / 1000).toFixed(2)}km`;
        } else {
          measurementKmMeter = `${lineLengthKm.toFixed(0)}m`;
        }

        let measurementMileFeet;
        if (lineLengthMile >= 1) {
          measurementMileFeet = `${lineLengthMile.toFixed(2)}mi`;
        } else {
          measurementMileFeet = `${(lineLengthMile * 5280).toFixed(0)}ft`;
        }

        centroid.properties = {
          measurementKmMeter,
          measurementMileFeet
        };
        centroid.id = feature.id;
        features.data.push(centroid);
      });
    }
  });
  dispatch(setAddMeasureData(features));
}
function removeSelectedDrawMeasure(selectedDraw, dispatch) {
  dispatch(setRemoveMeasureData(selectedDraw[0].id));
}

function addUnitToProperties(measure, unit) {
  const measureCopy = JSON.parse(JSON.stringify(measure));
  for (let i = 0; i < measureCopy.features.length; i++) {
    const feature = measureCopy.features[i];
    const properties = feature.properties;
    const updatedProperties = { ...properties, unit };
    feature.properties = updatedProperties;
  }
  return measureCopy;
}

export { updateDrawMeasure, removeSelectedDrawMeasure, addUnitToProperties };
