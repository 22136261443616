import { createSlice } from '@reduxjs/toolkit';

import { viirsLayer, viirsMultiYearLayer, viirs2022Layer, viirs2023Layer } from 'config/layers';
import { SOURCE_DATA } from 'config/layers/measureLayer';

const layerConfigs = [
  { ...viirsMultiYearLayer },
  { ...viirsLayer },
  { ...viirs2022Layer },
  { ...viirs2023Layer }
];

export const LayerStylesEnum = {
  Type: 'type',
  Temp: 'temp',
  Energy: 'energy'
};

const removeItemsById = (array, id) => array.filter((item) => item.id !== id);

const initialState = {
  style: LayerStylesEnum.Type,
  isRadiant: false,
  layers: layerConfigs.map((config) => ({
    ...config,
    enabled: config.enabled || false,
    eventAttached: false
  })),
  measureData: SOURCE_DATA
};

const layers = createSlice({
  name: 'layers',
  initialState,
  reducers: {
    setLayers(state, action) {
      state.layers = action.payload;
    },

    setLayerEventAttached(state, action) {
      const layerIndex = state.layers.findIndex((layer) => layer.id === action.payload);
      if (layerIndex !== -1) {
        state.layers[layerIndex].eventAttached = true;
      }
    },

    toggleLayerEnabled(state, action) {
      const layerIndex = state.layers.findIndex((layer) => layer.id === action.payload);
      if (layerIndex !== -1) {
        state.layers[layerIndex].enabled = !state.layers[layerIndex].enabled;
      }
    },

    toggleIsRadiant(state) {
      state.isRadiant = !state.isRadiant;
    },

    setLayerStyle(state, action) {
      state.style = action.payload;
    },

    setAddMeasureData(state, action) {
      const existingIndex = state.measureData.features.findIndex(
        (feature) => feature.id === action.payload.id
      );

      if (existingIndex !== -1) {
        state.measureData.features = removeItemsById(state.measureData.features, action.payload.id);
      }

      state.measureData.features.push(...action.payload.data);
    },

    setRemoveMeasureData(state, action) {
      state.measureData.features = removeItemsById(state.measureData.features, action.payload);
    },

    setResetMeasureData(state) {
      state.measureData = SOURCE_DATA;
    }
  }
});

export default layers.reducer;
export const {
  setLayers,
  toggleLayerEnabled,
  setLayerEventAttached,
  toggleIsRadiant,
  setLayerStyle,
  setAddMeasureData,
  setRemoveMeasureData,
  setResetMeasureData
} = layers.actions;
