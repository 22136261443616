import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { IntlProvider } from 'react-intl';

const loadLocaleData = () => import('utils/locales/en.json');

const Locales = ({ children }) => {
  const [messages, setMessages] = useState();

  useEffect(() => {
    loadLocaleData().then((d) => {
      setMessages(d.default);
    });
  }, []);

  return (
    <>
      {messages && (
        <IntlProvider locale="en" defaultLocale="en" messages={messages}>
          {children}
        </IntlProvider>
      )}
    </>
  );
};

Locales.propTypes = {
  children: PropTypes.node
};

export default Locales;
