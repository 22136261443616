export default {
  login: 'https://eogauth.mines.edu/auth/realms/master/protocol/openid-connect/token',
  clusterImageBaseUrl: 'https://eogdata.mines.edu/wwwdata/hidden/VNF_multiyear_2012-2020/files/',
  profileImageBaseUrl: 'https://eogdata.mines.edu/wwwdata/hidden/VNF_multiyear_2012-2020/files/',
  maps: {
    googleMapBaseUrl: 'https://www.google.com/maps/?q=loc:',
    bingMapBaseUrl: 'https://www.bing.com/maps/?cp=',
    esriMapBaseUrl: 'https://www.arcgis.com/home/webmap/viewer.html?center='
  },
  typeBaseUrl: '/facets/catalog_multiyear/type?orderBy=type&orderDir=desc',
  countryBaseUrl: '/facets/catalog_multiyear/country?limit=20&orderBycountry&orderDir=desc',
  eogVnfSite: 'https://eogdata.mines.edu/products/vnf/',
  vnfLicenseAgreementForm: 'https://eogdata.mines.edu/files/vnf_license_agreement_form.pdf',
  vnfLicenseAgreementInterimForm:
    'https://eogdata.mines.edu/files/vnf_license_agreement_interim_form.pdf',
  mapLegendImageLink: {
    multiyearList: 'resources/images/maplegend/multiyear_list.png',
    multiyearTemp: 'resources/images/maplegend/multiyear_temp.png',
    catalogList: 'resources/images/maplegend/catalog_list.png',
    catalogTemp: 'resources/images/maplegend/catalog_temp.png'
  },
  readmeCatalogProfiles: 'resources/images/readmeprofiles/readme-catalog-profiles.jpg'
};
