import { createRoot } from 'react-dom/client';

import InfoTpl from 'ui-component/cards/InfoTpl';
import viirsConfig from './viirsConfig';
import { getActiveTypeFilters, getActiveCategoryFilters } from 'utils/filterUtils';
import { popup, generateLayerConfig, getStyle, emitterClickHandler } from 'utils/layerUtils';
import flare from '../../assets/images/markerIcons/flare.png';
import gpp from '../../assets/images/markerIcons/gpp.png';
import landfill from '../../assets/images/markerIcons/landfill.png';
import lng from '../../assets/images/markerIcons/lng.png';
import petrochem from '../../assets/images/markerIcons/petrochem.png';
import refinery from '../../assets/images/markerIcons/refinery.png';
import unknown from '../../assets/images/markerIcons/unknown.png';
import industrial from '../../assets/images/markerIcons/industrial.png';
import opp from '../../assets/images/markerIcons/opp.png';
import volcano from '../../assets/images/markerIcons/volcano.png';
import biomass from '../../assets/images/markerIcons/biomass.png';
import downstream from '../../assets/images/markerIcons/downstream.png';
import midstream from '../../assets/images/markerIcons/midstream.png';

const viirs = {
  config: viirsConfig,
  id: viirsConfig.id,
  name: viirsConfig.name,
  description: viirsConfig.description,
  enabled: viirsConfig.enabled,
  metadata: viirsConfig.metadata,
  requiredAuthLevel: viirsConfig.requiredAuthLevel,
  tooltipText: viirsConfig.tooltipText,
  on: {
    load: (map, draw, callback, stateObj) => [
      () => {
        const iconNames = {
          flare,
          volcano,
          lng,
          industrial,
          landfill,
          refinery,
          unknown,
          whflare: flare,
          opp,
          gpp,
          biomass,
          midstream,
          gas: flare,
          petrochem,
          'downstream flare': downstream
        };
        const { layerState, filterState } = stateObj;
        const { circleRadius, circleColor, markerIcon } = getStyle(layerState.style, viirsConfig);
        if (map.getSource(viirsConfig.id) === undefined) {
          map.addSource(viirsConfig.id, viirsConfig.layerSource);
        }
        if (map.getLayer(viirsConfig.id) === undefined && viirsConfig.enabled) {
          Object.entries(iconNames).forEach(([type, path]) => {
            if (!map.hasImage(type)) {
              map.loadImage(path, (error, image) => {
                if (error) throw error;
                map.addImage(type, image);
              });
            }
          });
          map.addLayer(
            generateLayerConfig(
              viirsConfig.id,
              viirsConfig.layerType,
              viirsConfig.paint(circleRadius, circleColor),
              viirsConfig.id,
              viirsConfig.sourceLayer,
              viirsConfig.metadata,
              viirsConfig.layout(markerIcon, circleRadius)
            )
          );
          let countryFilter = null;
          const { selectedCountry } = filterState;
          if (filterState && selectedCountry && selectedCountry.alpha3) {
            countryFilter = ['==', 'iso', selectedCountry.alpha3];
          }

          let typeFilter = null;
          const activeTypeFilters = getActiveTypeFilters(filterState);
          if (activeTypeFilters.length) {
            typeFilter = ['in', 'type', ...activeTypeFilters];
          }

          let categoryFilter = null;
          const activeCategoryFilters = getActiveCategoryFilters(filterState);
          if (activeCategoryFilters.length) {
            categoryFilter = ['in', 'category', ...activeCategoryFilters];
          }

          const layersFilter = ['all'];
          if (countryFilter || typeFilter || categoryFilter) {
            if (countryFilter) {
              layersFilter.push(countryFilter);
            }
            if (typeFilter) {
              layersFilter.push(typeFilter);
            }
            if (categoryFilter) {
              layersFilter.push(categoryFilter);
            }
          }

          if (layersFilter.length > 1 && map.getLayer(viirsConfig.id)) {
            map.setFilter(viirsConfig.id, layersFilter);
          }
        }
      }
    ],
    click: (map, draw, callback) => [
      viirsConfig.id,
      (event) => emitterClickHandler(event, viirsConfig, map, draw, callback)
    ],
    mouseenter: (map, draw, callback, stateObj) => [
      viirsConfig.id,
      (event) => {
        const { properties } = event.features[0];
        const { lat, lon } = properties;
        const { id: layerId } = viirsConfig;
        map.getCanvas().style.cursor = 'pointer';
        const coordinates = [lon, lat].slice();
        if (properties) {
          const placeHolder = document.createElement('div');
          const root = createRoot(placeHolder); // Use createRoot here
          root.render(
            <InfoTpl
              properties={{ ...properties, layerId }}
              countries={stateObj.filterState.countries}
            />
          );
          popup.setDOMContent(placeHolder).setLngLat(coordinates).addTo(map);
        }
      }
    ],
    mouseleave: (map) => [
      viirsConfig.id,
      () => {
        map.getCanvas().style.cursor = '';
        popup.remove();
      }
    ]
  }
};

export default viirs;
