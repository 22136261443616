import PropTypes from 'prop-types';
import { Box, Modal } from '@mui/material';
import styled from '@emotion/styled';

import MapLegend from './MapLegend';
import { useState } from 'react';

export default function MapLegendModal({ MapLegendButton }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const height = 690;

  const width = 455; // If adding a third image, adjust the width to 655 to accommodate the third image.

  const ModalContent = styled(Box)`
    position: absolute;
    top: calc(50vh - ${height / 2}px);
    left: calc(50vw - ${width / 2}px);
    background: white;
    border-radius: 15px;
    outline: none;
    width: ${width}px;
    height: ${height}px;
    background: white;
    border-radius: 15px;
    outline: none;
    max-width: 655px; // Maximum width to accommodate up to three images.
    max-height: 690px;

    @media (max-width: 768px) {
      top: 40px;
      left: 25px;
      width: calc(100vw - 287px); // For three images, use 50px instead of 287px.
      height: calc(100vh - 50px);
      height: calc(100vh - 50px);
    }

    @media (max-width: 300px) {
      top: 40px;
      left: 15px;
      width: calc(100vw - 30px);
      height: calc(100vh - 30px);
    }
  `;
  return (
    <>
      <MapLegendButton onClick={handleOpen} />
      <Modal open={open} onClose={(e, reason) => reason !== 'backdropClick' && handleClose}>
        <ModalContent>
          <MapLegend onCancel={handleClose} />
        </ModalContent>
      </Modal>
    </>
  );
}

MapLegendModal.propTypes = {
  MapLegendButton: PropTypes.func
};
