/* eslint-disable no-restricted-syntax */
export const getLatLongValidation = (input) => {
  // const geomMatchRegex =
  //   /^(-?(90(\.0+)?|[1-8]?\d(\.\d+)?|180(\.0+)?))\s*[, ]\s*(-?(180(\.0+)?|1[0-7]\d(\.\d+)?|[0-9]\d(\.\d+)?|\d(\.\d+)?))$/;
  const geomMatchRegex =
    /^(-?(90(\.0+)?|[1-8]?\d(\.\d+)?|180(\.0+)?))[\t\s,]+(-?(180(\.0+)?|1[0-7]\d(\.\d+)?|[0-9]\d(\.\d+)?|\d(\.\d+)?))$/;

  const trimmedInput = input.replace(/^['"]|['"]$/g, '').trim();

  if (trimmedInput === '') {
    return [];
  }

  if (geomMatchRegex.test(trimmedInput)) {
    const match = geomMatchRegex.exec(trimmedInput);
    if (match) {
      return [parseFloat(match[1]), parseFloat(match[6])];
    }
  }

  return [];
};

export function calculateCenterCoordinates(geometry) {
  let centerLat;
  let centerLng;

  if (geometry.type === 'Polygon') {
    const coordinates = geometry.coordinates[0];
    centerLat = 0;
    centerLng = 0;

    for (const coord of coordinates) {
      centerLat += coord[1];
      centerLng += coord[0];
    }

    centerLat /= coordinates.length;
    centerLng /= coordinates.length;
  } else if (geometry.type === 'MultiPolygon') {
    let totalLat = 0;
    let totalLng = 0;
    let numCoords = 0;

    for (const polygon of geometry.coordinates) {
      for (const coordinates of polygon) {
        for (const coord of coordinates) {
          totalLat += coord[1];
          totalLng += coord[0];
          // eslint-disable-next-line no-plusplus
          numCoords++;
        }
      }
    }

    centerLat = totalLat / numCoords;
    centerLng = totalLng / numCoords;
  }

  return [centerLng, centerLat];
}
