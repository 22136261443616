// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Divider,
  List,
  ListItemButton,
  Modal,
  Tooltip,
  styled
} from '@mui/material';
import { IconPhoto, IconAdjustmentsHorizontal, IconFilter, IconMap2 } from '@tabler/icons';
import { setAggregatedProfileBtn } from 'store/slices/aggregatedProfiles';
import { ContextEnum, setCurrentContext } from 'store/slices/dashboard';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import { useDispatch, useSelector } from 'react-redux';
import MapLegend from 'ui-component/modals/MapLegendModal/MapLegend';
import { useState } from 'react';
import {
  getActiveCategoryFiltersCount,
  getActiveTypeFiltersCount,
  getActiveTypeLayerCount
} from 'utils/filterUtils';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const dashboardState = useSelector((state) => state.dashboard);
  const filtersState = useSelector((state) => state.filters);
  const layersState = useSelector((state) => state.layers);
  const { aggregatedProfileBtn } = useSelector((state) => state.aggregatedProfile);

  const color =
    dashboardState.currentContext === ContextEnum.Filter
      ? theme.palette.primary.light
      : theme.palette.primary.main;
  const background =
    dashboardState.currentContext === ContextEnum.Filter
      ? theme.palette.primary.main
      : theme.palette.primary.light;

  const height = 680;
  const width = 600;

  const handleAggregatedProfile = () => {
    dispatch(setAggregatedProfileBtn());
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const ModalContent = styled(Box)`
    position: absolute;
    top: calc(50vh - ${height / 2}px);
    left: calc(50vw - ${width / 2}px);
    width: ${width}px;
    height: ${height}px;
    background: white;
    border-radius: 15px;
    outline: none;
  `;

  const handleFilterToggle = () => {
    if (dashboardState.currentContext === ContextEnum.Filter) {
      dispatch(setCurrentContext(ContextEnum.Overview));
    } else {
      dispatch(setCurrentContext(ContextEnum.Filter));
    }
  };

  const handleLayersToggle = () => {
    if (dashboardState.currentContext === ContextEnum.Layers) {
      dispatch(setCurrentContext(ContextEnum.Overview));
    } else {
      dispatch(setCurrentContext(ContextEnum.Layers));
    }
  };

  const handleBackgroundsToggle = () => {
    if (dashboardState.currentContext === ContextEnum.Backgrounds) {
      dispatch(setCurrentContext(ContextEnum.Overview));
    } else {
      dispatch(setCurrentContext(ContextEnum.Backgrounds));
    }
  };

  const activeLayerCount = getActiveTypeLayerCount(layersState);

  let activeFilterCount = getActiveTypeFiltersCount(filtersState);

  activeFilterCount += getActiveCategoryFiltersCount(filtersState);

  return (
    <>
      <Modal open={open} onClose={(e, reason) => reason !== 'backdropClick' && handleClose}>
        <ModalContent>
          <MapLegend onCancel={handleClose} />
        </ModalContent>
      </Modal>
      <List>
        <Tooltip
          title={aggregatedProfileBtn ? 'Back to Map' : 'Aggregated Profiles'}
          placement="right"
        >
          <ListItemButton
            color="inherit"
            onClick={handleAggregatedProfile}
            sx={{
              borderRadius: 2,
              mt: 1,
              background: aggregatedProfileBtn
                ? theme.palette.primary.main
                : theme.palette.primary.light,
              color: aggregatedProfileBtn
                ? theme.palette.primary.light
                : theme.palette.primary.main,
              transition: 'all .2s ease-in-out',
              '&[aria-controls="menu-list-grow"],&:hover': {
                background: theme.palette.primary.main,
                color: theme.palette.primary.light
              }
            }}
          >
            {aggregatedProfileBtn ? (
              <ArrowBackIcon stroke={1.5} size="20px" style={{ fontSize: 20 }} />
            ) : (
              <HubOutlinedIcon stroke={1.5} size="20px" style={{ fontSize: 20 }} />
            )}
          </ListItemButton>
        </Tooltip>

        {!aggregatedProfileBtn && (
          <Tooltip title="Map Legend" placement="right">
            <ListItemButton
              onClick={handleOpen}
              sx={{
                borderRadius: 2,
                mt: 1,
                background: theme.palette.primary.light,
                color: theme.palette.primary.main,
                transition: 'all .2s ease-in-out',
                '&[aria-controls="menu-list-grow"],&:hover': {
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.light
                }
              }}
            >
              <IconMap2 />
            </ListItemButton>
          </Tooltip>
        )}

        {!aggregatedProfileBtn && (
          <ButtonGroup
            orientation="vertical"
            sx={{
              width: 1,
              mt: 1,
              '.MuiButtonGroup-grouped:not(:last-of-type)': {
                borderColor: '#D3D3D3'
              }
            }}
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
          >
            <Tooltip title="Backgrounds" placement="right">
              <Button
                onClick={handleBackgroundsToggle}
                color="inherit"
                sx={{
                  background:
                    dashboardState.currentContext === ContextEnum.Backgrounds
                      ? theme.palette.primary.main
                      : theme.palette.primary.light,
                  color:
                    dashboardState.currentContext === ContextEnum.Backgrounds
                      ? theme.palette.primary.light
                      : theme.palette.primary.main,
                  transition: 'all .2s ease-in-out',
                  '&[aria-controls="menu-list-grow"],&:hover': {
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.light
                  }
                }}
              >
                <IconPhoto />
              </Button>
            </Tooltip>

            <Tooltip title="Layers" placement="right">
              <Button
                onClick={handleLayersToggle}
                color="inherit"
                sx={{
                  background:
                    dashboardState.currentContext === ContextEnum.Layers
                      ? theme.palette.primary.main
                      : theme.palette.primary.light,
                  color:
                    dashboardState.currentContext === ContextEnum.Layers
                      ? theme.palette.primary.light
                      : theme.palette.primary.main,
                  transition: 'all .2s ease-in-out',
                  '&[aria-controls="menu-list-grow"],&:hover': {
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.light
                  }
                }}
              >
                <Badge
                  sx={{
                    top: '-15px',
                    right: '-35px'
                  }}
                  badgeContent={activeLayerCount}
                  color="primary"
                />
                <IconAdjustmentsHorizontal />
              </Button>
            </Tooltip>

            <Tooltip title="Filters" placement="right">
              <Button
                onClick={handleFilterToggle}
                color="inherit"
                sx={{
                  background,
                  color,
                  transition: 'all .2s ease-in-out',
                  '&[aria-controls="menu-list-grow"],&:hover': {
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.light
                  }
                }}
              >
                <Badge
                  sx={{
                    top: '-15px',
                    right: '-35px'
                  }}
                  badgeContent={activeFilterCount}
                  color="primary"
                />
                <IconFilter />
              </Button>
            </Tooltip>
          </ButtonGroup>
        )}
      </List>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
};

export default NavGroup;
