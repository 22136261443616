import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import KPI1 from 'views/kpi1';
import KPI2 from 'views/kpi2';

const Dashboard = Loadable(lazy(() => import('views/dashboard')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Dashboard />
    },
    {
      path: '/dashboard',
      element: <Dashboard />
    },
    {
      path: '/kpi1',
      element: <KPI1 />
    },
    {
      path: '/kpi2',
      element: <KPI2 />
    }
  ]
};

export default MainRoutes;
