import { useTheme } from '@mui/material/styles';
import { Badge, Button, Tooltip, ButtonGroup } from '@mui/material';
import { IconFilter, IconPhoto, IconAdjustmentsHorizontal } from '@tabler/icons';
import { dispatch, useSelector } from 'store';
import { ContextEnum, setCurrentContext } from 'store/slices/dashboard';
import {
  getActiveCategoryFiltersCount,
  getActiveTypeFiltersCount,
  getActiveTypeLayerCount
} from 'utils/filterUtils';

const ToggleButtonGroup = () => {
  const theme = useTheme();

  const dashboardState = useSelector((state) => state.dashboard);
  const filtersState = useSelector((state) => state.filters);
  const layersState = useSelector((state) => state.layers);

  const handleFilterToggle = () => {
    if (dashboardState.currentContext === ContextEnum.Filter) {
      dispatch(setCurrentContext(ContextEnum.Overview));
    } else {
      dispatch(setCurrentContext(ContextEnum.Filter));
    }
  };

  const handleLayersToggle = () => {
    if (dashboardState.currentContext === ContextEnum.Layers) {
      dispatch(setCurrentContext(ContextEnum.Overview));
    } else {
      dispatch(setCurrentContext(ContextEnum.Layers));
    }
  };

  const handleBackgroundsToggle = () => {
    if (dashboardState.currentContext === ContextEnum.Backgrounds) {
      dispatch(setCurrentContext(ContextEnum.Overview));
    } else {
      dispatch(setCurrentContext(ContextEnum.Backgrounds));
    }
  };

  const color =
    dashboardState.currentContext === ContextEnum.Filter
      ? theme.palette.primary.light
      : theme.palette.primary.main;
  const background =
    dashboardState.currentContext === ContextEnum.Filter
      ? theme.palette.primary.main
      : theme.palette.primary.light;

  const activeLayerCount = getActiveTypeLayerCount(layersState);
  let activeFilterCount = getActiveTypeFiltersCount(filtersState);

  activeFilterCount += getActiveCategoryFiltersCount(filtersState);

  if (filtersState.selectedCountry) {
    activeFilterCount += 1;
  }

  return (
    <ButtonGroup
      sx={{
        mr: 1,
        '.MuiButtonGroup-grouped:not(:last-of-type)': {
          borderColor: '#D3D3D3'
        }
      }}
      disableElevation
      variant="contained"
      aria-label="Disabled elevation buttons"
    >
      <Tooltip title="Backgrounds">
        <Button
          onClick={handleBackgroundsToggle}
          color="inherit"
          sx={{
            background:
              dashboardState.currentContext === ContextEnum.Backgrounds
                ? theme.palette.primary.main
                : theme.palette.primary.light,
            color:
              dashboardState.currentContext === ContextEnum.Backgrounds
                ? theme.palette.primary.light
                : theme.palette.primary.main,
            transition: 'all .2s ease-in-out',
            '&[aria-controls="menu-list-grow"],&:hover': {
              background: theme.palette.primary.main,
              color: theme.palette.primary.light
            }
          }}
        >
          <IconPhoto />
        </Button>
      </Tooltip>

      <Tooltip title="Layers">
        <Button
          onClick={handleLayersToggle}
          color="inherit"
          sx={{
            background:
              dashboardState.currentContext === ContextEnum.Layers
                ? theme.palette.primary.main
                : theme.palette.primary.light,
            color:
              dashboardState.currentContext === ContextEnum.Layers
                ? theme.palette.primary.light
                : theme.palette.primary.main,
            transition: 'all .2s ease-in-out',
            '&[aria-controls="menu-list-grow"],&:hover': {
              background: theme.palette.primary.main,
              color: theme.palette.primary.light
            }
          }}
        >
          <Badge
            sx={{
              top: '-23px',
              right: '-30px'
            }}
            badgeContent={activeLayerCount}
            color="primary"
          />

          <IconAdjustmentsHorizontal />
        </Button>
      </Tooltip>

      <Tooltip title="Filters">
        <Button
          onClick={handleFilterToggle}
          color="inherit"
          sx={{
            background,
            color,
            transition: 'all .2s ease-in-out',
            '&[aria-controls="menu-list-grow"],&:hover': {
              background: theme.palette.primary.main,
              color: theme.palette.primary.light
            }
          }}
        >
          <Badge
            sx={{
              top: '-23px',
              right: '-30px'
            }}
            badgeContent={activeFilterCount}
            color="primary"
          />
          <IconFilter />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};

export default ToggleButtonGroup;
