import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, useMediaQuery } from '@mui/material';

import useConfig from 'hooks/useConfig';
import LoginForm from './LoginForm';
import LoginButton from './LoginButton';
import { useDispatch } from 'store';
import { login, setLoading } from 'store/slices/user';

import Logo from 'assets/images/logo.png';
import { isEmpty } from 'lodash';

export default function LoginComponent({ handleClose }) {
  const theme = useTheme();
  const { baseUrls } = useConfig();
  const dispatch = useDispatch();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    dispatch(setLoading(true));
    setTimeout(() => dispatch(login(baseUrls.login, username, password, handleClose)), 1000);
  };

  return (
    <Stack spacing={3} mt={8} onKeyPress={(e) => e.key === 'Enter' && handleLogin()}>
      <img src={Logo} alt="logo" style={{ width: '400px' }} />
      <Typography variant="caption" fontSize="14px" textAlign={matchDownSM ? 'center' : 'inherit'}>
        Enter your credentials to continue
      </Typography>
      <LoginForm formData={{ username, password, setUsername, setPassword }} />
      <LoginButton onClick={handleLogin} disable={isEmpty(username) || isEmpty(password)} />
    </Stack>
  );
}

LoginComponent.propTypes = {
  handleClose: PropTypes.func
};
