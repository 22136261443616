import { Avatar, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'store';
import { openDrawer } from 'store/slices/menu';
import LogoSection from './LogoSection';
import MainMenu from './MainMenu';
import ToggleButtonGroup from './ToggleButtonGroup';
import ToggleButtonGroupLite from './ToggleButtonGroupLite';
import MapLegendModal from 'ui-component/modals/MapLegendModal';
import MapLegendButton from 'layout/MainLayout/Header/MapLegendButton';
import MapLegendButtonLite from 'layout/MainLayout/Header/MapLegendButtonLite';
import AggregatedProfileButton from './AggregatedProfileButton';
import AggregatedProfileButtonLite from './AggregatedProfileButtonLite';
import { IconMenu2 } from '@tabler/icons';
import SearchMap from './SearchMap';

const Header = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const color = theme.palette.primary.main;
  const background = theme.palette.primary.light;
  const { aggregatedProfileBtn } = useSelector((state) => state.aggregatedProfile);
  const { drawerOpen } = useSelector((state) => state.menu);
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: '100%'
          }
        }}
      >
        {!matchUpSm && (
          <Avatar
            variant="rounded"
            sx={{
              cursor: 'pointer',
              display: { xs: 'block', sm: 'block', md: 'block', lg: 'none' },
              background,
              color,
              transition: 'all .2s ease-in-out',
              '&[aria-controls="menu-list-grow"],&:hover': {
                background: theme.palette.primary.main,
                color: theme.palette.primary.light
              },
              p: 1
            }}
            onClick={() => dispatch(openDrawer(!drawerOpen))}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="25px" />
          </Avatar>
        )}

        <Box
          component="span"
          sx={{
            display: 'none',
            '@media screen and (min-width: 751px)': {
              display: 'block'
            }
          }}
        >
          <LogoSection />
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      <Box>{!aggregatedProfileBtn && <SearchMap />}</Box>

      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
        <AggregatedProfileButton />
      </Box>

      <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'none' } }}>
        <AggregatedProfileButtonLite />
      </Box>

      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
        {!aggregatedProfileBtn && <MapLegendModal MapLegendButton={MapLegendButton} />}
      </Box>

      <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'none' } }}>
        {!aggregatedProfileBtn && <MapLegendModal MapLegendButton={MapLegendButtonLite} />}
      </Box>

      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
        {!aggregatedProfileBtn && <ToggleButtonGroup />}
      </Box>

      <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'none' } }}>
        {!aggregatedProfileBtn && <ToggleButtonGroupLite />}
      </Box>

      <MainMenu />
    </>
  );
};

export default Header;
