export const DRAW_LABELS_SOURCE_ID = 'source-draw-labels';
export const DRAW_LABELS_LAYER_ID = 'layer-draw-labels';
export const SOURCE_DATA = {
  type: 'FeatureCollection',
  features: []
};

export const MEASURE_LAYER_LAYOUT = {
  'text-font': ['Klokantech Noto Sans Bold'],
  'text-field': [
    'case',
    ['==', ['get', 'unit'], 'km'],
    ['get', 'measurementKmMeter'],
    ['==', ['get', 'unit'], 'mi'],
    ['get', 'measurementMileFeet'],
    ''
  ],
  'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
  'text-radial-offset': 0.5,
  'text-justify': 'auto',
  'text-letter-spacing': 0.05,
  'text-size': ['interpolate', ['linear'], ['zoom'], 13, 12.0, 14, 14.0, 18, 16.0]
};

export const MEASURE_LAYER_PAINT = {
  'text-color': '#D20C0C',
  'text-halo-width': 10
};

export const MEASURE_LAYER_TYPE = 'symbol';
