import axios from 'axios';
import jwt from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';

import { openSnackbar } from 'store/slices/snackbar';
import { paidUser, adminUser } from 'config/roles.json';

export const AuthRoles = {
  Public: { key: 'public', level: 0 }, // anyone, logged in or not
  User: { key: 'user', level: 1 }, // any logged in users
  Subscriber: { key: 'subscriber', level: 2 }, // paid subscribers
  Admin: { key: 'admin', level: 9 } // admin only (edit permissions)
};

const initialState = {
  error: null,
  loading: false,
  isLoggedIn: false,
  authRole: AuthRoles.Public,
  token: null,
  loginData: null,
  decodedData: null,
  profile: {
    name: null,
    email: null,
    familyName: null,
    givenName: null
  }
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state) {
      state.isLoggedIn = true;
      state.error = null;
    },

    logout(state) {
      state.isLoggedIn = false;
      state.authRole = AuthRoles.Public;
      state.token = null;
      state.loginData = null;
      state.decodedData = null;
      state.profile = initialState.profile;
      state.error = null;
    },

    setAuthRole(state, action) {
      state.authRole = action.payload;
    },

    setToken(state, action) {
      state.token = action.payload;
    },

    setProfile(state, action) {
      state.profile = action.payload;
    },

    setLoading(state, action) {
      state.loading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },

    setLoginData(state, action) {
      state.loginData = action.payload;
    },

    setDecodedData(state, action) {
      state.decodedData = action.payload;
    }
  }
});

export default user.reducer;

export const { logout, setLoading } = user.actions;

const authBody = {
  clientId: 'eogdata_oidc',
  clientSecret: '2677ad81-521b-4869-8480-6d05b9e57d48',
  grantType: 'password'
};

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
};

const generateParams = (username, password) => {
  const params = new URLSearchParams();
  params.append('client_id', authBody.clientId);
  params.append('client_secret', authBody.clientSecret);
  params.append('grant_type', authBody.grantType);
  params.append('username', username);
  params.append('password', password);
  return params;
};

const parseData = (data) => {
  const { access_token: accessToken } = data;
  const decodedData = jwt(accessToken);
  const { name, email, family_name: familyName, given_name: givenName } = decodedData;
  const profileObj = {
    name,
    email,
    familyName,
    givenName
  };
  return { decodedData, accessToken, profileObj };
};

const isEmpty = (groups) => !(groups.length > 0);
const userBelongsToGroup = (userGroups, roleGroups) => {
  if (isEmpty(userGroups) || isEmpty(roleGroups)) {
    return false;
  }
  if (userGroups.some((g) => roleGroups.includes(g))) {
    return true;
  }
  return false;
};

const getAuthRole = (data) => {
  const { group: userGroups } = data;
  const { groups: paidGroups } = paidUser;
  const { groups: adminGroups } = adminUser;
  if (userBelongsToGroup(userGroups, adminGroups)) {
    return AuthRoles.Admin;
  }
  if (userBelongsToGroup(userGroups, paidGroups)) {
    return AuthRoles.Subscriber;
  }
  return AuthRoles.User;
};

export const login = (url, username, password, handleClose) => async (dispatch) => {
  const params = generateParams(username, password);
  try {
    const { data } = await axios.post(url, params, config);
    console.log('login success');
    const { decodedData, accessToken, profileObj } = parseData(data);
    const authRole = getAuthRole(decodedData);
    dispatch(user.actions.setLoginData(data));
    dispatch(user.actions.setDecodedData(decodedData));
    dispatch(user.actions.setToken(accessToken));
    dispatch(user.actions.setProfile(profileObj));
    dispatch(user.actions.setAuthRole(authRole));
    dispatch(user.actions.setLoading(false));
    dispatch(user.actions.login());
    dispatch(
      openSnackbar({
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        open: true,
        message: 'Successfully logged in',
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    handleClose();
  } catch (error) {
    console.log('Error trying to login: ', error);
    dispatch(user.actions.setError(error));
    dispatch(user.actions.setLoading(false));
    dispatch(
      openSnackbar({
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        open: true,
        message: 'Error logging in',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
  }
};
