const serializeColors = (typeColors) => {
  const typeColorsList = [];
  typeColors.forEach((type) => {
    typeColorsList.push(type.name);
    typeColorsList.push(type.color);
  });
  return typeColorsList;
};

const serializeIcons = (typeColors) => {
  const typeColorsList = [];
  typeColors.forEach((type) => {
    typeColorsList.push(type.name);
    typeColorsList.push(type.name);
  });
  return typeColorsList;
};

// eslint-disable-next-line import/prefer-default-export
export { serializeColors, serializeIcons };
