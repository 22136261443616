import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { gridSpacing } from 'store/constant';
import './chart-data/style.css';

import { IconTemperature, IconFlame, IconSunWind, IconWorld, IconMenu2 } from '@tabler/icons';
import DeviceThermostatSharpIcon from '@mui/icons-material/DeviceThermostatSharp';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FCE6E1',
    color: '#F24E29',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}));

const HeaderStatus = ({ tempdata }) => {
  const { lon, lat, source, background, radiant, radiative, footprint } = tempdata;
  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container alignItems="center" spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={2} />
          <Grid item>
            <Grid
              container
              alignItems="center"
              spacing={0}
              justifyContent={matchDownXs ? 'space-between' : 'center'}
            >
              <Grid item>
                <Typography
                  sx={{
                    color: '#2799F5'
                  }}
                >
                  <IconWorld width="30" height="30" strokeWidth="2.25" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="flex-start"
              spacing={0.5}
              justifyContent={matchDownXs ? 'space-between' : 'center'}
            >
              <Grid item>
                <Typography
                  sx={{
                    color: '#F24E29'
                  }}
                >
                  <IconMenu2 width="25" height="25" strokeWidth="3.7" />
                </Typography>
              </Grid>
              <Grid item sm zeroMinWidth>
                <HtmlTooltip title={`Latitude : ${lat}`} placement="top">
                  <Typography variant="h3"> {lat}</Typography>
                </HtmlTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="flex-start" spacing={0.5} justifyContent="center">
              <Grid item>
                <Typography
                  sx={{
                    color: '#F24E29'
                  }}
                >
                  <IconMenu2 className="rotate" width="25" height="25" strokeWidth="3.5" />
                </Typography>
              </Grid>
              <Grid item sm zeroMinWidth>
                <HtmlTooltip title={`Longitude : ${lon}`} placement="top">
                  <Typography variant="h3"> {lon} </Typography>
                </HtmlTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={4}>
          <Grid item>
            <Grid
              container
              alignItems="center"
              spacing={2}
              justifyContent={matchDownXs ? 'space-between' : 'center'}
            >
              <Grid item>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <HtmlTooltip title={`Temperature Source : ${source} K `} placement="top">
                      <Typography
                        sx={{
                          width: 40,
                          height: 40,
                          color: '#2799F5',
                          borderRadius: '12px',
                          padding: 1,
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? theme.palette.background.default
                              : theme.palette.primary.light
                        }}
                      >
                        <IconTemperature strokeWidth="2.25" />
                      </Typography>
                    </HtmlTooltip>
                  </Grid>
                  <Grid item sm zeroMinWidth>
                    <HtmlTooltip title={`Temperature Source : ${source} K `} placement="top">
                      <Typography variant="h4"> {source} K</Typography>
                    </HtmlTooltip>
                    <Typography variant="subheading" color="inherit" noWrap>
                      Source
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              spacing={0.5}
              justifyContent={matchDownXs ? 'space-between' : 'center'}
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <HtmlTooltip
                      title={`Temperature Background : ${background} K `}
                      placement="top"
                    >
                      <Typography
                        sx={{
                          width: 40,
                          height: 40,
                          color: '#7ABBF0',
                          borderRadius: '12px',
                          padding: 1,
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? theme.palette.background.default
                              : theme.palette.primary.light
                        }}
                      >
                        <DeviceThermostatSharpIcon stroke={1} strokeWidth="2.25" />
                      </Typography>
                    </HtmlTooltip>
                  </Grid>
                  <Grid item sm zeroMinWidth>
                    <HtmlTooltip
                      title={`Temperature Background : ${background} K `}
                      placement="top"
                    >
                      <Typography variant="h4"> {background} K</Typography>
                    </HtmlTooltip>
                    <Typography variant="subheading" color="inherit" noWrap>
                      Background
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0.5} />
          <Grid item>
            <Grid
              container
              alignItems="center"
              spacing={0.5}
              justifyContent={matchDownXs ? 'space-between' : 'center'}
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <HtmlTooltip
                      title={`Radiant Heat Intensity : ${radiant} W/m2 `}
                      placement="top"
                    >
                      <Typography
                        sx={{
                          width: 40,
                          height: 40,
                          color: theme.palette.error.main,
                          borderRadius: '12px',
                          padding: 1,
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? theme.palette.background.default
                              : '#ffe9e9'
                        }}
                      >
                        <LocalFireDepartmentOutlinedIcon strokeWidth="2.25" />
                      </Typography>
                    </HtmlTooltip>
                  </Grid>
                  <Grid item sm zeroMinWidth>
                    <HtmlTooltip
                      title={`Radiant Heat Intensity : ${radiant} W/m2 `}
                      placement="top"
                    >
                      <Typography variant="h4"> {radiant} W/m2</Typography>
                    </HtmlTooltip>
                    <Typography variant="subheading" color="inherit" noWrap>
                      Radiant
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              spacing={0.5}
              justifyContent={matchDownXs ? 'space-between' : 'center'}
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <HtmlTooltip title={`Radiative Heat : ${radiative} MW `} placement="top">
                      <Typography
                        sx={{
                          width: 40,
                          height: 40,
                          color: theme.palette.error.main,
                          borderRadius: '12px',
                          padding: 1,
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? theme.palette.background.default
                              : '#ffe9e9'
                        }}
                      >
                        <IconFlame strokeWidth="2.25" />
                      </Typography>
                    </HtmlTooltip>
                  </Grid>
                  <Grid item sm zeroMinWidth>
                    <HtmlTooltip title={`Radiative Heat : ${radiative} MW `} placement="top">
                      <Typography variant="h4">{radiative} MW</Typography>
                    </HtmlTooltip>
                    <Typography variant="subheading" color="inherit" noWrap>
                      Radiative
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0.5} />
          <Grid item>
            <Grid
              container
              alignItems="center"
              spacing={0.5}
              justifyContent={matchDownXs ? 'space-between' : 'center'}
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <HtmlTooltip title={`Source Footrpint : ${footprint} m2 `} placement="top">
                      <Typography
                        sx={{
                          width: 40,
                          height: 40,
                          color: '#2799F5',
                          borderRadius: '12px',
                          padding: 1,
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? theme.palette.background.default
                              : '#E3F2FD'
                        }}
                      >
                        <IconSunWind strokeWidth="2.25" />
                      </Typography>
                    </HtmlTooltip>
                  </Grid>
                  <Grid item sm zeroMinWidth>
                    <HtmlTooltip title={`Source Footrpint : ${footprint} m2 `} placement="top">
                      <Typography variant="h4">{footprint} m2</Typography>
                    </HtmlTooltip>
                    <Typography variant="subheading" color="inherit" noWrap>
                      Footrpint
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

HeaderStatus.propTypes = {
  tempdata: PropTypes.object
};
export default HeaderStatus;
