import PropTypes from 'prop-types';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import HeaderStatus from './HeaderStatus';
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import useConfig from 'hooks/useConfig';
import { gridSpacing } from 'store/constant';
import chartData from './chart-data/profile-chart-data';

const MainChart = ({ isLoading, tempdata, myseries }) => {
  console.log('main menu::', myseries);
  const theme = useTheme();
  const { navType } = useConfig();

  const { primary } = theme.palette.text;
  const darkLight = theme.palette.dark.light;
  const grey200 = theme.palette.grey[200];
  const grey500 = theme.palette.grey[500];

  const noDetection = '#B39DDB';
  const detection = theme.palette.orange.main;
  const constrains = theme.palette.success.dark;
  const curveModel = '#2196F3';
  const irSource = theme.palette.grey[900];
  const background = theme.palette.primary.dark;

  React.useEffect(() => {
    const newChartData = {
      ...chartData.options,
      colors: [noDetection, detection, constrains, curveModel, irSource, background],
      xaxis: {
        labels: {
          style: {
            colors: [
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary
            ]
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: [primary]
          }
        },
        title: {
          text: 'W/m2/sr/um',
          style: {
            color: 'black',
            fontSize: '15px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 200,
            cssClass: 'apexcharts-yaxis-title'
          }
        },
        axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0
        }
      },
      grid: {
        borderColor: navType === 'dark' ? darkLight + 20 : grey200
      },
      tooltip: {
        theme: navType === 'dark' ? 'dark' : 'light'
      },
      legend: {
        labels: {
          colors: grey500
        }
      },
      series: myseries
    };

    // do not load chart when loading
    if (!isLoading) {
      ApexCharts.exec(`bar-chart`, 'updateOptions', newChartData);
    }
  }, [
    navType,
    noDetection,
    detection,
    constrains,
    curveModel,
    irSource,
    background,
    primary,
    darkLight,
    grey200,
    isLoading,
    grey500,
    myseries
  ]);

  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <HeaderStatus tempdata={tempdata} />
        </Grid>
        <Grid item xs={12}>
          <Chart {...chartData} />
        </Grid>
      </Grid>
    </>
  );
};

MainChart.propTypes = {
  isLoading: PropTypes.bool,
  tempdata: PropTypes.object,
  myseries: PropTypes.array
};

export default MainChart;
