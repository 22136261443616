import { createSlice } from '@reduxjs/toolkit';
import { axiosApi } from 'utils/axios';

const initialState = {
  texts: {}
};

const staticTexts = createSlice({
  name: 'staticTexts',
  initialState,
  reducers: {
    setTexts(state, action) {
      state.texts = action.payload;
    }
  }
});

export const { setTexts, setError } = staticTexts.actions;

export const loadStaticTexts = () => async (dispatch) => {
  try {
    const response = await axiosApi.get('static/text');
    dispatch(setTexts(response.data));
  } catch (error) {
    console.log('Error ', error);
  }
};

export default staticTexts.reducer;
