import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  customAttribution: [
    '© <a href="https://eogdata.mines.edu/products/vnf/#credit" target="_blank">Earth Observation Group</a>'
  ],
  initialized: false,
  initialLayersLoaded: false,
  map: null,
  mapStyle: null,
  zoom: 2,
  lat: 40,
  lon: -100,
  unit: 'km'
};

const map = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setCustomAttribution(state, action) {
      state.customAttribution = action.payload;
    },

    setInitialized(state, action) {
      state.initialized = action.payload;
    },

    setInitialLayersLoaded(state, action) {
      state.initialLayersLoaded = action.payload;
    },

    setMap(state, action) {
      state.map = action.payload;
    },

    setMapStyle(state, action) {
      state.mapStyle = action.payload;
    },

    setZoom(state, action) {
      state.zoom = action.payload;
    },

    setLat(state, action) {
      state.lat = action.payload;
    },

    setLon(state, action) {
      state.lon = action.payload;
    },
    setSelectedUnit(state, action) {
      state.unit = action.payload;
    },
    RESET_STATE() {
      return { ...initialState };
    }
  }
});

export default map.reducer;
export const { setInitialized, setInitialLayersLoaded, setMap, setMapStyle, setSelectedUnit } =
  map.actions;

export const resetMapState = createAsyncThunk('map/resetState', async (_, { dispatch }) => {
  dispatch(map.actions.RESET_STATE());
});

export const setTileStyle = (url) => (dispatch) => {
  dispatch(
    map.actions.setMapStyle({
      layers: [
        {
          id: 'base-style',
          source: 'base-style-source',
          type: 'raster'
        }
      ],
      sources: {
        'base-style-source': {
          attribution: '',
          tiles: Array.isArray(url) ? [...url] : [url],
          type: 'raster'
        }
      },
      version: 8
    })
  );
};

export const setMapTilerStyle = (url, apiKey) => (dispatch) => {
  dispatch(map.actions.setMapStyle(`${url}/style.json?key=${apiKey}`));
};
