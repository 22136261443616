// material-ui
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';

// ===========================||  Today, Month Annual||=========================== //

const HeaderGroupButton = ({ type, handelerSeries }) => {
  const theme = useTheme();
  return (
    <>
      <Grid item>
        <Button
          style={{ borderColor: '#EDE7F6' }}
          variant={type === 'Today' ? 'contained' : 'outlined'}
          onClick={handelerSeries}
          sx={{
            transition: 'all .2s ease-in-out',
            fontSize: 15,
            backgroundColor:
              type === 'Today' ? theme.palette.error.main : theme.palette.orange.light,
            color: type === 'Today' ? theme.palette.orange.light : '#F24E29',
            '&[aria-controls="menu-list-grow"],&:hover': {
              background:
                theme.palette.mode === 'dark'
                  ? theme.palette.warning.dark
                  : theme.palette.error.main,
              color: theme.palette.orange.light
            }
          }}
        >
          Today
        </Button>
      </Grid>
      <Grid item>
        <Button
          style={{ borderColor: '#EDE7F6' }}
          variant={type === 'Month' ? 'contained' : 'outlined'}
          onClick={handelerSeries}
          sx={{
            transition: 'all .2s ease-in-out',
            fontSize: 15,
            backgroundColor:
              type === 'Month' ? theme.palette.error.main : theme.palette.orange.light,
            color: type === 'Month' ? theme.palette.orange.light : '#F24E29',
            '&[aria-controls="menu-list-grow"],&:hover': {
              background:
                theme.palette.mode === 'dark'
                  ? theme.palette.warning.dark
                  : theme.palette.error.main,
              color: theme.palette.orange.light
            }
          }}
        >
          Month
        </Button>
      </Grid>
      <Grid item>
        <Button
          style={{ borderColor: '#EDE7F6' }}
          variant={type === 'Annual' ? 'contained' : 'outlined'}
          onClick={handelerSeries}
          sx={{
            transition: 'all .2s ease-in-out',
            fontSize: 15,
            backgroundColor:
              type === 'Annual' ? theme.palette.error.main : theme.palette.orange.light,
            color: type === 'Annual' ? theme.palette.orange.light : '#F24E29',
            '&[aria-controls="menu-list-grow"],&:hover': {
              background:
                theme.palette.mode === 'dark'
                  ? theme.palette.warning.dark
                  : theme.palette.error.main,
              color: theme.palette.orange.light
            }
          }}
        >
          Annual
        </Button>
      </Grid>
    </>
  );
};

HeaderGroupButton.propTypes = {
  type: PropTypes.string,
  handelerSeries: PropTypes.func
};

export default HeaderGroupButton;
